import { useCallback, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ButtonBase, Container } from "@mui/material";
import { Group } from "@ses-mams/api-contract";
import emptyListImageUrl from "~/assets/emptyList.png";
import { EmptyState } from "~/components/common/emptyState";
import { GroupListItem } from "~/components/common/group";
import { Box } from "~/components/ui/box";
import { Button } from "~/components/ui/button";
import { Heading } from "~/components/ui/heading";
import { Spinner } from "~/components/ui/spinner";
import { Stack } from "~/components/ui/stack";
import {
  DraggableList,
  DraggableRenderItemParams,
} from "~/components/ui/draggableList";
import { HamburgerFilledIcon } from "~/components/ui/icon";
import { useHasAnyRole } from "~/context/auth";
import { useSelectedUnit } from "~/context/unit/SelectedUnitContextProvider";
import { useGroupsQuery } from "~/hooks/useGroupsQuery";
import { tsr } from "~/utils/client";

export const GroupsPage = () => {
  const unitId = useParams().unitId as string;

  useSelectedUnitBehaviour();

  const { groups, isLoading } = useGroupsQuery(unitId);

  const reorderGroups = useReorderGroups(unitId);

  const renderItem = useCallback(
    ({ item, bind }: DraggableRenderItemParams<Group>) => {
      return (
        <Link
          key={item.id}
          to={`${item.id}/edit`}
          // TODO: Temporary inline styles
          style={{ color: "inherit", textDecoration: "none", width: "100%" }}
        >
          <GroupListItem
            group={item}
            endAdornment={
              <ButtonBase
                {...bind}
                onClick={e => e.preventDefault()}
                sx={theme => ({
                  cursor: "grab",
                  touchAction: "none",
                  padding: theme.tokens.spacing.xsmall,
                  borderRadius: theme.tokens.border.radius.small,
                  ":hover": {
                    backgroundColor:
                      theme.tokens.colors.background.surfaceMuted,
                  },
                })}
              >
                <HamburgerFilledIcon />
              </ButtonBase>
            }
          />
        </Link>
      );
    },
    []
  );

  return (
    <Container maxWidth="md">
      <Stack gap="xxlarge">
        <Stack direction="row" justify="space-between">
          <Heading level="1">Groups</Heading>

          <Button href="add">Add Group</Button>
        </Stack>

        <Stack gap="medium" dividers>
          {isLoading ? (
            <Box display="flex" align="center" justify="center">
              <Spinner />
            </Box>
          ) : !groups.length ? (
            <EmptyState
              image={<img src={emptyListImageUrl} />}
              title="No groups added"
              description="You haven't created any groups yet. Tap below to create a group"
              action={<Button href="add">Add Group</Button>}
            />
          ) : (
            <DraggableList
              renderItem={renderItem}
              data={groups}
              onOrderChange={reorderGroups}
            />
          )}
        </Stack>
      </Stack>
    </Container>
  );
};

const useReorderGroups = (unitId: string) => {
  const queryClient = tsr.useQueryClient();

  const { mutateAsync } = tsr.groups.setGroupOrder.useMutation();

  return useCallback(
    async (groups: Array<Group>) => {
      const data = await mutateAsync({
        params: {
          unitId,
        },
        body: {
          groupIds: groups.map(({ id }) => id),
        },
      });

      queryClient.units.listGroups.setQueryData(["groups", unitId], data);

      queryClient.units.listGroups.setQueryData(["groups", { unitId }, true], {
        ...data,
        body: data.body.filter(group => group.reportable),
      });
    },
    [unitId]
  );
};

const useSelectedUnitBehaviour = () => {
  const hasOcRole = useHasAnyRole(["OperationalCoordinator"]);
  const hasAdminRole = useHasAnyRole(["Administrator", "SystemAdministrator"]);
  const { selectedUnit, memberBelongsToSelectedUnit } = useSelectedUnit();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      !(
        selectedUnit &&
        ((hasOcRole && memberBelongsToSelectedUnit) || hasAdminRole)
      )
    ) {
      navigate("/units");
      return;
    }

    navigate(`/units/${selectedUnit.id}/groups`);
  }, [hasOcRole, selectedUnit, memberBelongsToSelectedUnit]);
};
