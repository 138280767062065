import { captureException } from "@sentry/react";
import {
  Capability,
  Cluster,
  Group,
  Member,
  Unit,
  Zone,
} from "@ses-mams/api-contract";
import { SelectAllMembersQuery } from "@ses-mams/react-utils";
import { useCallback, useMemo, useState } from "react";
import { EditCapabilitiesList } from "~/components/common/editCapabilitiesList";
import { EditClustersList } from "~/components/common/editClustersList";
import { EditGroupsList } from "~/components/common/editGroupsList";
import { EditMemberList } from "~/components/common/editMemberList";
import { EditUnitsList } from "~/components/common/editUnitsList";
import { EditZonesList } from "~/components/common/editZonesList";
import { Box } from "~/components/ui/box";
import { Button, IconButton } from "~/components/ui/button";
import { Divider } from "~/components/ui/divider";
import {
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
} from "~/components/ui/drawer";
import { Heading } from "~/components/ui/heading";
import { ChevronLeftOutlineIcon } from "~/components/ui/icon";
import { Spinner } from "~/components/ui/spinner";
import { Stack } from "~/components/ui/stack";
import { useToast } from "~/components/ui/toast";
import { useOutOfAreaActivationQuery } from "~/pages/requests/hooks";
import { tsr } from "~/utils/client";

type AddOOAAMembersProps = {
  onBack: () => void;
  onClose: () => void;
  activationId: string;
};

export const AddOOAAMembers = ({
  onClose,
  onBack,
  activationId,
}: AddOOAAMembersProps) => {
  const { addToast } = useToast();
  const queryClient = tsr.useQueryClient();

  const [zones, setZones] = useState<Array<Zone>>([]);
  const [clusters, setClusters] = useState<Array<Cluster>>([]);
  const [groups, setGroups] = useState<Array<Group>>([]);
  const [units, setUnits] = useState<Array<Unit>>([]);
  const [capabilities, setCapabilities] = useState<Array<Capability>>([]);
  const [members, setMembers] = useState<Array<Member>>([]);
  const [selectAllMembersQuery, setSelectAllMembersQuery] =
    useState<SelectAllMembersQuery>();

  const { mutateAsync, isPending: isAddingMembers } =
    tsr.outOfAreaActivations.addMembers.useMutation();

  const { data, isLoading: isLoadingActivation } = useOutOfAreaActivationQuery({
    activationId,
  });

  const activation = data?.body;

  const showZoneSection = useMemo(() => {
    return ["State", "InterZone"].includes(activation?.level ?? "");
  }, [activation?.level]);

  const showClusterSection = useMemo(() => {
    return !["IntraCluster", "InterUnit"].includes(activation?.level ?? "");
  }, [activation?.level]);

  const handleDone = useCallback(async () => {
    try {
      await mutateAsync({
        body: {
          memberIds: members.map(({ id }) => id),
          zoneIds: zones.map(z => z.id),
          clusterIds: clusters.map(c => c.id),
          groupIds: groups.map(g => g.id),
          capabilityIds: capabilities.map(c => c.id),
          unitIds: units.map(u => u.id),
          selectAllMembersQuery,
        },
        params: {
          activationId,
        },
      });

      queryClient.invalidateQueries({
        queryKey: ["out-of-area-activation", { activationId }, "members-v2"],
      });

      onBack();
    } catch (err) {
      captureException(err);

      addToast({
        tone: "critical",
        title: "Sorry, something went wrong",
        message: "Please try again",
      });
    }
  }, [
    members,
    selectAllMembersQuery,
    activationId,
    zones,
    clusters,
    units,
    groups,
    capabilities,
  ]);

  const activateForValue = useMemo(() => {
    if (activation?.unit) {
      return activation?.unit.name;
    }

    if (activation?.zone) {
      return activation?.zone?.name;
    }

    if (activation?.cluster) {
      return activation?.cluster?.name;
    }

    return undefined;
  }, [activation]);

  return (
    <>
      <DrawerHeader onClose={onClose}>
        <Stack direction="row" gap="small" align="center">
          <IconButton size="medium" onClick={onBack}>
            <ChevronLeftOutlineIcon />
          </IconButton>
          Add Members
        </Stack>
      </DrawerHeader>
      <DrawerContent>
        {isLoadingActivation ? (
          <Box display="flex" grow={1} justify="center">
            <Spinner />
          </Box>
        ) : (
          <Stack gap="xlarge">
            <EditMemberList
              showAvailabilityStatus
              selectedMembers={members}
              onChangeSelectedMembers={setMembers}
              selectAllMembersQuery={selectAllMembersQuery}
              setSelectAllMembersQuery={setSelectAllMembersQuery}
              outOfAreaActivationId={activationId}
            />

            <Stack gap="medium">
              <Heading level="3">Add Collections</Heading>
              <Divider />
            </Stack>

            {showZoneSection && (
              <EditZonesList
                selectedZones={zones}
                onChangeSelectedZones={setZones}
              />
            )}

            {showClusterSection && (
              <EditClustersList
                selectedClusters={clusters}
                onChangeSelectedClusters={setClusters}
              />
            )}

            <EditGroupsList
              selectedGroups={groups}
              onChangeSelectedGroups={setGroups}
            />

            <EditUnitsList
              selectedUnits={units}
              onChangeSelectedUnits={setUnits}
            />

            <EditCapabilitiesList
              selectedCapabilities={capabilities}
              onChangeSelectedCapabilities={setCapabilities}
              unitId={activation?.unit?.id ?? undefined}
              clusterId={activation?.cluster?.id ?? undefined}
              zoneId={activation?.zone?.id ?? undefined}
              disclaimer={
                activateForValue
                  ? `Showing capabilities for ${activateForValue}`
                  : undefined
              }
            />
          </Stack>
        )}
      </DrawerContent>
      <DrawerFooter>
        <Button onClick={handleDone} busy={isAddingMembers}>
          Done
        </Button>
      </DrawerFooter>
    </>
  );
};
