import { captureException } from "@sentry/react";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "~/components/ui/button";
import { useConfirmationDialog } from "~/components/ui/confirmationDialog";
import { TrashOutlineIcon } from "~/components/ui/icon";
import { useToast } from "~/components/ui/toast";
import { tsr } from "~/utils/client";

type ArchiveActivityButtonProps = {
  activityId: string;
};

export const ArchiveActivityButton = ({
  activityId,
}: ArchiveActivityButtonProps) => {
  const navigate = useNavigate();
  const queryClient = tsr.useQueryClient();

  const { mutateAsync, isPending: isClosingActivation } =
    tsr.activities.close.useMutation();

  const { showConfirmationDialog } = useConfirmationDialog();
  const { addToast } = useToast();

  const handleDeleteActivity = useCallback(async () => {
    try {
      await mutateAsync({
        params: {
          id: activityId,
        },
      });

      await queryClient.invalidateQueries({
        queryKey: ["activity-requests", "list"],
      });

      navigate("/requests?tab=activity");
    } catch (error) {
      captureException(error);

      addToast({
        tone: "critical",
        title: "Sorry, something went wrong",
        message: "Please try again",
      });
    }
  }, [activityId]);

  return (
    <Button
      variant="destructive"
      busy={isClosingActivation}
      startIcon={TrashOutlineIcon}
      onClick={() =>
        showConfirmationDialog({
          text: "You will not be able to undo this action.",
          onConfirm: handleDeleteActivity,
        })
      }
    >
      Archive Activity
    </Button>
  );
};
