import { Container } from "@mui/material";
import {
  formatDateForServer,
  mapDeploymentsToAvailabilityBlocks,
  useAvailabilityBlocksForm,
} from "@ses-mams/react-utils";
import { AvailabilityBlocksFormSchema } from "@ses-mams/validation";
import { captureException } from "@sentry/react";
import { useCallback, useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Box } from "~/components/ui/box";
import { AvailabilityBlocksForm } from "~/components/common/availabilityBlocksForm";
import { useToast } from "~/components/ui/toast";
import { tsr } from "~/utils/client";
import { useOutOfAreaActivationRequestDetails } from "../../hooks";
import { Heading } from "~/components/ui/heading";
import { Stack } from "~/components/ui/stack";
import { Button } from "~/components/ui/button";
import { Spinner } from "~/components/ui/spinner";
import { DeploymentDetails } from "../components";
import { BackButton } from "~/components/common/navigation";

export const OOAARequestAvailabilityResponsePage = () => {
  const activationId = useParams().activationId as string;
  const [searchParams] = useSearchParams();
  const roleIds = searchParams.get("roleIds") as string;

  const { isLoading: isLoadingRequestDetails, data: requestDetailsData } =
    useOutOfAreaActivationRequestDetails(activationId);

  const outOfAreaActivationRequest = requestDetailsData?.body;
  const deployments = outOfAreaActivationRequest?.activation?.deployments;

  const { control, trigger, getValues, handleSubmit, reset } =
    useAvailabilityBlocksForm(deployments);

  const { isPending: isResponding, respond } =
    useRespondToOutOfAreaActivationRequest({
      roleIds,
      requestId: outOfAreaActivationRequest?.request?.id,
    });

  useEffect(() => {
    if (!deployments?.length) {
      return;
    }

    reset({
      availabilityBlocks: mapDeploymentsToAvailabilityBlocks(deployments),
    });
  }, [deployments]);

  if (isLoadingRequestDetails || !outOfAreaActivationRequest) {
    return (
      <Box display="flex" grow={1} justify="center">
        <Spinner />
      </Box>
    );
  }

  return (
    <Container maxWidth="md">
      <form onSubmit={handleSubmit(respond)}>
        <Stack gap="xlarge">
          <BackButton />

          <Stack direction="row" align="center" justify="space-between">
            <Heading level="2">Availability</Heading>

            <Button type="submit" busy={isResponding}>
              Done
            </Button>
          </Stack>

          <DeploymentDetails deployments={deployments} />

          <AvailabilityBlocksForm
            control={control}
            trigger={trigger}
            getValues={getValues}
          />
        </Stack>
      </form>
    </Container>
  );
};

type Params = {
  requestId?: string;
  roleIds?: string;
};

const useRespondToOutOfAreaActivationRequest = ({
  requestId,
  roleIds,
}: Params) => {
  const navigate = useNavigate();
  const { addToast } = useToast();
  const queryClient = tsr.useQueryClient();

  const { isPending, mutateAsync } =
    tsr.outOfAreaActivationRequests.respond.useMutation();

  const respond = useCallback(
    async (form: AvailabilityBlocksFormSchema) => {
      if (!requestId) {
        return;
      }

      try {
        const response = await mutateAsync({
          params: {
            requestId,
          },
          body: {
            roleIds: roleIds ? roleIds.split(",") : undefined,
            availabilityBlocks: form.availabilityBlocks.map(block => ({
              ...block,
              start: formatDateForServer(block.start),
              end: formatDateForServer(block.end),
            })),
          },
        });

        const activationId = response.body.activation.id;

        queryClient.outOfAreaActivationRequests.get.setQueryData(
          ["out-of-area-activation-requests", "details", { activationId }],
          response
        );

        await queryClient.invalidateQueries({
          queryKey: ["out-of-area-activation-requests", "list"],
        });

        navigate(`/requests/out-of-area-activations/${activationId}`);
      } catch (error) {
        captureException(error);

        addToast({
          tone: "critical",
          title: "Sorry, something went wrong",
          message: "Please try again",
        });
      }
    },
    [mutateAsync, requestId, roleIds]
  );

  return {
    isPending,
    respond,
  };
};
