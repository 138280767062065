import { captureException } from "@sentry/react";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { OutOfAreaActivationCloseReasonDrawer } from "~/components/common/outOfAreaActivationCloseReasonDrawer/OutOfAreaActivationCloseReasonDrawer";
import { Button } from "~/components/ui/button";
import { useConfirmationDialog } from "~/components/ui/confirmationDialog";
import { DrawerProps } from "~/components/ui/drawer";
import { useToast } from "~/components/ui/toast";
import { tsr } from "~/utils/client";

type CloseButtonProps = {
  activationId: string;
};

export const CloseButton = ({ activationId }: CloseButtonProps) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  return (
    <>
      <Button variant="destructive" onClick={() => setIsDrawerOpen(true)}>
        Close Request
      </Button>
      <CloseReasonDrawer
        activationId={activationId}
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
      />
    </>
  );
};

type CloseReasonDrawerProps = Omit<DrawerProps, "children"> & CloseButtonProps;

const CloseReasonDrawer = ({
  activationId,
  open,
  onClose,
}: CloseReasonDrawerProps) => {
  const navigate = useNavigate();
  const queryClient = tsr.useQueryClient();

  const { mutateAsync, isPending: isClosing } =
    tsr.outOfAreaActivations.close.useMutation();

  const { showConfirmationDialog } = useConfirmationDialog();
  const { addToast } = useToast();

  const handleClose = useCallback(
    async (reason: string) => {
      try {
        await mutateAsync({
          params: {
            id: activationId,
          },
          body: {
            reason,
          },
        });

        await queryClient.invalidateQueries({
          queryKey: ["out-of-area-activation-requests", "list"],
        });

        navigate("/requests?tab=out-of-area");
      } catch (error) {
        captureException(error);

        addToast({
          tone: "critical",
          title: "Sorry, something went wrong",
          message: "Please try again",
        });
      }
    },
    [activationId]
  );

  return (
    <OutOfAreaActivationCloseReasonDrawer
      busy={isClosing}
      open={open}
      onClose={onClose}
      onSubmit={reason =>
        showConfirmationDialog({
          text: "Are you sure you want to close this request?",
          onConfirm: () => handleClose(reason),
        })
      }
    />
  );
};
