import { captureException } from "@sentry/react";
import { ContactGroup, Group, Member } from "@ses-mams/api-contract";
import {
  SelectAllMembersQuery,
  formatSelectAllMembersQuery,
  getUpdatedPagedData,
} from "@ses-mams/react-utils";
import { useCallback, useState } from "react";
import { EditMemberList } from "~/components/common/editMemberList";
import { SelectUnitGroupsList } from "~/components/common/selectUnitGroupsList";
import { Button, IconButton } from "~/components/ui/button";
import {
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
} from "~/components/ui/drawer";
import { ChevronLeftOutlineIcon } from "~/components/ui/icon";
import { Stack } from "~/components/ui/stack";
import { useToast } from "~/components/ui/toast";
import { tsr } from "~/utils/client";

export type AddActivityScheduleMembersProps = {
  scheduleId: string;
  onBack: () => void;
  onClose: () => void;
  unitId: string;
};

export const AddActivityScheduleMembers = ({
  scheduleId,
  onBack,
  onClose,
  unitId,
}: AddActivityScheduleMembersProps) => {
  const [members, setMembers] = useState<Array<Member>>([]);
  const [groups, setGroups] = useState<Array<Group>>([]);
  const [contactGroups, setContactGroups] = useState<Array<ContactGroup>>([]);
  const [selectAllMembersQuery, setSelectAllMembersQuery] =
    useState<SelectAllMembersQuery>();

  const queryClient = tsr.useQueryClient();
  const { addToast } = useToast();

  const { mutateAsync, isPending: isAddingMembers } =
    tsr.activitySchedules.addMembers.useMutation();

  const onDone = useCallback(async () => {
    try {
      const result = await mutateAsync({
        params: {
          activityScheduleId: scheduleId,
        },
        body: {
          groupIds: groups.map(({ id }) => id),
          contactGroupIds: contactGroups.map(({ id }) => id),
          memberIds: members.map(({ id }) => id),
          selectAllMembersQuery: formatSelectAllMembersQuery(
            selectAllMembersQuery
          ),
        },
      });

      await Promise.all([
        queryClient.setQueryData(
          ["activity-schedules", scheduleId, "members"],
          getUpdatedPagedData(result.body.items)
        ),

        queryClient.invalidateQueries({
          queryKey: ["activity-schedules", "isInvited", scheduleId],
          exact: true,
        }),
      ]);

      onBack();
    } catch (error) {
      captureException(error);

      addToast({
        tone: "critical",
        title: "Sorry, something went wrong",
        message: "Please try again",
      });
    }
  }, [members, groups, contactGroups, selectAllMembersQuery, onBack]);

  return (
    <>
      <DrawerHeader onClose={onClose}>
        <Stack direction="row" gap="small" align="center">
          <IconButton size="medium" onClick={onBack}>
            <ChevronLeftOutlineIcon />
          </IconButton>
          Add Members
        </Stack>
      </DrawerHeader>
      <DrawerContent>
        <Stack gap="large">
          <SelectUnitGroupsList
            groups={groups}
            contactGroups={contactGroups}
            setGroups={setGroups}
            setContactGroups={setContactGroups}
            unitId={unitId}
          />

          <EditMemberList
            showAvailabilityStatus
            selectedMembers={members}
            onChangeSelectedMembers={setMembers}
            selectAllMembersQuery={selectAllMembersQuery}
            setSelectAllMembersQuery={setSelectAllMembersQuery}
            unitId={unitId}
            activityScheduleId={scheduleId}
          />
        </Stack>
      </DrawerContent>
      <DrawerFooter>
        <Button busy={isAddingMembers} onClick={onDone}>
          Done
        </Button>
      </DrawerFooter>
    </>
  );
};
