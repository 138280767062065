import { tsr } from "~/utils/client";
import { useCallback } from "react";
import {
  CreateOoaaActivationParams,
  transformOoaaActivationFormForCreateOrUpdate,
} from "@ses-mams/react-utils";

export const useOutOfAreaActivationMutations = () => {
  const queryClient = tsr.useQueryClient();

  const { mutateAsync: createOoaaActivationMutation } =
    tsr.outOfAreaActivations.create.useMutation();

  const { mutateAsync: updateOoaaActivationMutation } =
    tsr.outOfAreaActivations.update.useMutation();

  const createOoaaActivation = useCallback(
    (params: CreateOoaaActivationParams) =>
      createOoaaActivationMutation({
        body: transformOoaaActivationFormForCreateOrUpdate(params),
      }),
    []
  );

  const updateOoaaActivation = useCallback(
    async (activationId: string, params: CreateOoaaActivationParams) => {
      const updatedActivation = await updateOoaaActivationMutation({
        body: transformOoaaActivationFormForCreateOrUpdate(params),
        params: {
          id: activationId,
        },
      });

      await Promise.all([
        queryClient.outOfAreaActivations.get.setQueryData(
          ["out-of-area-activation", { activationId }],
          updatedActivation
        ),
        queryClient.invalidateQueries({
          queryKey: ["out-of-area-activation-requests"],
        }),
      ]);
    },
    []
  );

  return {
    createOoaaActivation,
    updateOoaaActivation,
  };
};
