import { zodResolver } from "@hookform/resolvers/zod";
import { captureException } from "@sentry/react";
import { useUpdateEffect } from "@ses-mams/react-utils";
import {
  OutOfAreaActivationDeclineFormSchema,
  outOfAreaActivationDeclineFormSchema,
} from "@ses-mams/validation";
import { useCallback } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Box } from "~/components/ui/box";
import { Button } from "~/components/ui/button";
import {
  Drawer,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerProps,
} from "~/components/ui/drawer";
import { RadioGroupField, RadioGroupFieldItem } from "~/components/ui/radio";
import { Spinner } from "~/components/ui/spinner";
import { Stack } from "~/components/ui/stack";
import { TextField } from "~/components/ui/textField";
import { useToast } from "~/components/ui/toast";
import { tsr } from "~/utils/client";

type DeclineParams = {
  requestIds: Array<string>;
};

type DeclineCommentsSheetProps = Omit<DrawerProps, "children"> &
  DeclineParams & {
    onSuccess?: () => void;
  };

export const DeclineCommentsSheet = ({
  requestIds,
  open,
  onClose,
  onSuccess,
}: DeclineCommentsSheetProps) => {
  const { control, formState, register, reset, handleSubmit, watch } =
    useForm<OutOfAreaActivationDeclineFormSchema>({
      resolver: zodResolver(outOfAreaActivationDeclineFormSchema),
      mode: "onBlur",
      defaultValues: {
        reason: "Other",
      },
    });

  const { data, isLoading: isLoadingDeclineReasons } =
    tsr.outOfAreaActivationApprovals.listDeclineReasons.useQuery({
      queryKey: ["out-of-area-activation-approvals", "decline-reasons"],
    });

  useUpdateEffect(() => {
    if (!open) {
      reset();
    }
  }, [open]);

  const { decline, isPending: isDecliningApprovals } = useDeclineApprovals({
    requestIds,
    onSuccess: useCallback(() => {
      onClose();
      onSuccess?.();
    }, [onSuccess, onClose]),
  });

  const watchReason = watch("reason");

  return (
    <Drawer open={open} onClose={onClose}>
      {isLoadingDeclineReasons ? (
        <Box>
          <Spinner />
        </Box>
      ) : (
        <>
          <DrawerHeader onClose={onClose}>
            Reason for declining member
          </DrawerHeader>
          <form
            onSubmit={handleSubmit(decline)}
            style={{ display: "flex", flexDirection: "column", height: "100%" }}
          >
            <DrawerContent>
              <Stack gap="large">
                <Controller
                  name="reason"
                  control={control}
                  render={({ field: { onChange, value }, fieldState }) => (
                    <RadioGroupField
                      label="Reason"
                      hideLabel
                      value={value}
                      onChange={onChange}
                      errorMessage={fieldState.error?.message}
                    >
                      {(data?.body ?? []).map(reason => (
                        <RadioGroupFieldItem
                          key={reason}
                          label={reason}
                          value={reason}
                        />
                      ))}
                    </RadioGroupField>
                  )}
                />

                {watchReason === "Other" && (
                  <TextField
                    {...register("comments", { shouldUnregister: true })}
                    label="Comments"
                    hideLabel
                    multiline
                    rows={4}
                    placeholder="Enter comments"
                    errorMessage={formState.errors?.comments?.message}
                  />
                )}
              </Stack>
            </DrawerContent>

            <DrawerFooter>
              <Button type="submit" fullWidth busy={isDecliningApprovals}>
                Done
              </Button>
            </DrawerFooter>
          </form>
        </>
      )}
    </Drawer>
  );
};

type Params = DeclineParams & {
  onSuccess: () => void;
};

const useDeclineApprovals = ({ requestIds, onSuccess }: Params) => {
  const navigate = useNavigate();
  const { addToast } = useToast();
  const queryClient = tsr.useQueryClient();

  const { isPending, mutateAsync } =
    tsr.outOfAreaActivationApprovals.decline.useMutation();

  const decline = useCallback(
    async ({ comments, reason }: OutOfAreaActivationDeclineFormSchema) => {
      try {
        await mutateAsync({
          body: {
            reason,
            comments,
            requestIds,
          },
        });

        await Promise.all([
          queryClient.invalidateQueries({
            queryKey: ["out-of-area-activation-approvals-v2"],
          }),
          queryClient.invalidateQueries({
            queryKey: ["out-of-area-activation-requests"],
          }),
        ]);

        navigate("/out-of-area-activation-approvals");

        onSuccess();
      } catch (error) {
        captureException(error);

        addToast({
          tone: "critical",
          title: "Sorry, something went wrong",
          message: "Please try again",
        });
      }
    },
    [requestIds, onSuccess]
  );

  return {
    isPending,
    decline,
  };
};
