import { Container } from "@mui/material";
import { BackButton } from "~/components/common/navigation";
import { Row, Stack } from "~/components/ui/stack";

import { useNavigate } from "react-router-dom";
import { tsr } from "~/utils/client";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { Divider } from "~/components/ui/divider";
import { Button } from "~/components/ui/button";
import { Controller } from "react-hook-form";
import { Heading } from "~/components/ui/heading";
import { TextField } from "~/components/ui/textField";
import { DateTimeField } from "~/components/ui/dateTimeField";
import { z } from "zod";
import { ConfirmButton } from "./components";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  formatDateForServer,
  formatTwentyFourHourTime,
} from "@ses-mams/react-utils";
import { RadioGroupField, RadioGroupFieldItem } from "~/components/ui/radio";
import { Broadcast } from "@ses-mams/api-contract";
import { dateAndTime } from "@ses-mams/validation";

const visibilityOptions = [
  "ShowInApp",
  "ShowInWeb",
  "ShowInAppAndWeb",
] as const;

const visibilityOptionLabelMap = {
  ShowInApp: "Show in App",
  ShowInWeb: "Show in Web",
  ShowInAppAndWeb: "Show in App and Web",
};

const timingOptions = ["SendNow", "SendLater"] as const;

const timingOptionLabelMap = {
  SendNow: "Send now",
  SendLater: "Send later",
};

const buttonStatusMap: Record<
  "Draft" | "Scheduled" | "Current" | "Complete" | "New",
  string[]
> = {
  New: ["Discard", "Save as Draft", "Save and Broadcast"],
  Draft: ["Discard", "Delete Draft", "Save", "Save and Broadcast"],
  Scheduled: ["Discard", "Delete Message", "Save", "Save and Broadcast"],
  Current: ["End Broadcast"],
  Complete: [],
};

const broadcastFormSchema = z
  .object({
    title: z
      .string({ required_error: "Title is required" })
      .trim()
      .min(1, "Title is required")
      .max(255, "The maximum length is 255 characters"),
    description: z
      .string()
      .trim()
      .max(10000, "The maximum length is 10000 characters")
      .min(1, "Description is required"),
    visibility: z.enum(visibilityOptions),
    timing: z.enum(timingOptions),
    start: dateAndTime().partial().optional(),
    end: dateAndTime().partial().optional(),
  })
  .superRefine(({ start, end, timing }, ctx) => {
    if (timing === "SendLater" && (!start?.date || !start?.time)) {
      ctx.addIssue({
        code: "custom",
        message: `Start ${!start?.date ? "date" : "time"} is required`,
        path: ["start"],
      });
    }

    if (!start?.date || !start?.time || !end?.date || !end?.time) {
      return;
    }

    if (start.date >= end.date) {
      ctx.addIssue({
        code: "custom",
        message: "Start time must be before End time",
        path: ["start"],
      });
      ctx.addIssue({
        code: "custom",
        message: "End time must be after Start time",
        path: ["end"],
      });
      return;
    }

    if (start.date < new Date()) {
      ctx.addIssue({
        code: "custom",
        message: "Start time cannot be in the past",
        path: ["start"],
      });
    }

    if (end.date < new Date()) {
      ctx.addIssue({
        code: "custom",
        message: "End time cannot be in the past",
        path: ["end"],
      });
    }

    return true;
  });

type BroadcastFormSchema = z.infer<typeof broadcastFormSchema>;

export const BroadcastDetailsPage = ({
  existingBroadcast,
}: {
  existingBroadcast?: Broadcast;
}) => {
  const navigate = useNavigate();
  const queryClient = tsr.useQueryClient();

  const cantEdit =
    existingBroadcast &&
    (existingBroadcast?.status === "Current" ||
      existingBroadcast?.status === "Complete");

  const formMethods = useForm<BroadcastFormSchema>({
    resolver: zodResolver(broadcastFormSchema),
    defaultValues: existingBroadcast
      ? {
          title: existingBroadcast.title,
          description: existingBroadcast.description,
          start: existingBroadcast.start
            ? {
                date: new Date(existingBroadcast.start),
                time: formatTwentyFourHourTime(
                  new Date(existingBroadcast.start)
                ),
              }
            : undefined,
          end: existingBroadcast.end
            ? {
                date: new Date(existingBroadcast.end),
                time: formatTwentyFourHourTime(new Date(existingBroadcast.end)),
              }
            : undefined,

          timing: existingBroadcast.timing,
          visibility: existingBroadcast.visibility,
        }
      : {},
  });

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    register,
    formState: { errors },
  } = formMethods;

  const timingValue = useWatch({
    control,
    name: "timing",
  });

  const { mutateAsync: createMutation } = tsr.broadcasts.create.useMutation();
  const { mutateAsync: updateMutation } = tsr.broadcasts.update.useMutation();
  const { mutateAsync: deleteMutation } = tsr.broadcasts.delete.useMutation();
  const { mutateAsync: closeMutation } = tsr.broadcasts.close.useMutation();

  const onSaveAsDraft = async () => {
    const { start, end, ...restValues } = formMethods.getValues();

    await createMutation({
      body: {
        ...restValues,
        start: start?.date ? formatDateForServer(start.date) : undefined,
        end: end?.date ? formatDateForServer(end.date) : undefined,
        draft: true,
      },
    });

    navigate("/admin/broadcast");
  };

  const onUpdateSkipValidation = async () => {
    const { start, end, ...restValues } = formMethods.getValues();

    const result = await updateMutation({
      params: { id: existingBroadcast?.id as string },
      body: {
        ...restValues,
        start: start?.date ? formatDateForServer(start.date) : undefined,
        end: end?.date ? formatDateForServer(end.date) : undefined,
        draft: true,
      },
    });

    queryClient.broadcasts.get.setQueryData(
      ["broadcasts", existingBroadcast?.id],
      result
    );
    navigate("/admin/broadcast");
  };

  const onUpdate = async (formData: BroadcastFormSchema) => {
    const { start, end, ...restValues } = formData;
    const result = await updateMutation({
      params: { id: existingBroadcast?.id as string },
      body: {
        ...restValues,
        start: start?.date ? formatDateForServer(start.date) : undefined,
        end: end?.date ? formatDateForServer(end.date) : undefined,
        draft: false,
      },
    });

    queryClient.broadcasts.get.setQueryData(
      ["broadcasts", existingBroadcast?.id],
      result
    );
    navigate("/admin/broadcast");
  };

  const onClose = async () => {
    await closeMutation({
      params: { id: existingBroadcast?.id as string },
    });

    navigate("/admin/broadcast");
  };

  const onDelete = async () => {
    await deleteMutation({
      params: { id: existingBroadcast?.id as string },
    });

    navigate("/admin/broadcast");
  };

  const onSubmitExisting = async (formData: BroadcastFormSchema) => {
    const { start, end, ...restValues } = formData;
    await updateMutation({
      params: { id: existingBroadcast?.id as string },
      body: {
        ...restValues,
        start:
          restValues.timing === "SendLater" && start?.date
            ? formatDateForServer(start.date)
            : formatDateForServer(new Date()),
        end: end?.date ? formatDateForServer(end.date) : undefined,
        draft: false,
      },
    });

    navigate("/admin/broadcast");
  };

  const onCreateAndSubmit = async (formData: BroadcastFormSchema) => {
    const { start, end, ...restValues } = formData;
    await createMutation({
      body: {
        ...restValues,
        start:
          restValues.timing === "SendLater" && start?.date
            ? formatDateForServer(start.date)
            : formatDateForServer(new Date()),
        end: end?.date ? formatDateForServer(end.date) : undefined,
        draft: false,
      },
    });

    navigate("/admin/broadcast");
  };

  const ButtonComponents: Record<string, JSX.Element> = {
    Discard: (
      <ConfirmButton
        key={"Discard"}
        text={"Discard"}
        variant="destructive"
        onConfirm={() => navigate("/admin/broadcast")}
        title={"Discard broadcast message?"}
        description={
          "You have not saved your changes, if you Discard Changes all changes will be lost."
        }
        confirmButtonText={"Discard changes"}
      />
    ),
    "Delete Draft": (
      <ConfirmButton
        key={"Delete Draft"}
        text={"Delete Draft"}
        variant="destructive"
        onConfirm={onDelete}
        title={"Delete draft broadcast message?"}
        description={
          "You have chosen to delete this draft message, if you Delete Draft the original message along with any changes you have made will be lost."
        }
        confirmButtonText={"Delete Draft"}
      />
    ),
    "Delete Message": (
      <ConfirmButton
        key={"Delete Message"}
        text={"Delete Message"}
        variant="destructive"
        onConfirm={onDelete}
        title={"Delete scheduled broadcast message?"}
        description={
          "You have chosen to delete this message scheduled for broadcast, if you Delete Message the original message along with any changes you have made will be lost."
        }
        confirmButtonText={"Delete Message"}
      />
    ),
    Save:
      timingValue === "SendNow" && existingBroadcast?.status === "Scheduled" ? (
        <ConfirmButton
          key={"Save with confirm"}
          isBusy={isSubmitting}
          text={"Save"}
          variant="primary"
          onConfirm={handleSubmit(onSubmitExisting)}
          title={"Broadcast this message now?"}
          description={"Are you sure you want to broadcast this message now?"}
          confirmButtonText={"Broadcast"}
        />
      ) : (
        <Button
          key={"Save"}
          onClick={_ =>
            existingBroadcast?.status === "Draft"
              ? onUpdateSkipValidation()
              : handleSubmit(onUpdate)(_)
          }
          busy={isSubmitting}
          variant="primary"
        >
          Save
        </Button>
      ),
    "Save as Draft": (
      <Button
        key={"Save as Draft"}
        onClick={onSaveAsDraft}
        busy={isSubmitting}
        variant="secondary"
      >
        Save as Draft
      </Button>
    ),
    "Save and Broadcast":
      timingValue === "SendLater" ? (
        <Button
          key={"Save and Broadcast later"}
          onClick={handleSubmit(
            existingBroadcast ? onSubmitExisting : onCreateAndSubmit
          )}
          busy={isSubmitting}
          variant="primary"
        >
          Save and Broadcast
        </Button>
      ) : (
        <ConfirmButton
          key={"Save and Broadcast"}
          isBusy={isSubmitting}
          text={"Save and broadcast"}
          variant="primary"
          onConfirm={handleSubmit(
            existingBroadcast ? onSubmitExisting : onCreateAndSubmit
          )}
          title={"Broadcast this message now?"}
          description={"Are you sure you want to broadcast this message now?"}
          confirmButtonText={"Broadcast"}
        />
      ),
    "End Broadcast": (
      <ConfirmButton
        key={"End Broadcast"}
        isBusy={isSubmitting}
        text={"End Broadcast"}
        variant="destructive"
        onConfirm={onClose}
        title={"End this broadcast now?"}
        description={"Are you sure you want to end this broadcast?"}
        confirmButtonText={"End Broadcast"}
      />
    ),
  };

  const buttons = buttonStatusMap[existingBroadcast?.status || "New"].map(
    buttonName => ButtonComponents[buttonName]
  );

  return (
    <Container maxWidth="sm">
      <Stack gap="xlarge">
        <BackButton />
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(onCreateAndSubmit)}>
            <Stack gap="xlarge">
              <Heading level="1">
                {!existingBroadcast
                  ? "New"
                  : {
                      Draft: "Edit Draft",
                      Scheduled: "Edit Scheduled",
                      Current: "Live",
                      Complete: "View",
                    }[existingBroadcast.status]}{" "}
                Broadcast Message
              </Heading>
              <Divider />
              <TextField
                {...register("title")}
                label="Title"
                placeholder="Enter title"
                errorMessage={errors.title?.message}
                disabled={cantEdit}
              />
              <TextField
                {...register("description")}
                label="Description"
                placeholder="Enter description"
                errorMessage={errors.description?.message}
                multiline
                rows={4}
                disabled={cantEdit}
              />
              <Controller
                name="visibility"
                control={control}
                render={({ field: { onChange, value }, fieldState }) => (
                  <RadioGroupField
                    disabled={cantEdit}
                    label="Visibility"
                    value={value}
                    onChange={onChange}
                    errorMessage={fieldState.error?.message}
                  >
                    {visibilityOptions.map(option => (
                      <RadioGroupFieldItem
                        key={option}
                        label={visibilityOptionLabelMap[option]}
                        value={option}
                      />
                    ))}
                  </RadioGroupField>
                )}
              />
              <Controller
                name="timing"
                control={control}
                render={({ field: { onChange, value }, fieldState }) => (
                  <RadioGroupField
                    disabled={cantEdit}
                    label="Timing"
                    value={value}
                    onChange={onChange}
                    errorMessage={fieldState.error?.message}
                  >
                    {timingOptions.map(option => (
                      <RadioGroupFieldItem
                        key={option}
                        label={timingOptionLabelMap[option]}
                        value={option}
                      />
                    ))}
                  </RadioGroupField>
                )}
              />
              {timingValue === "SendLater" ? (
                <Controller
                  name="start"
                  control={control}
                  render={({ field, fieldState }) => (
                    <DateTimeField
                      label="Start Time"
                      value={field.value}
                      onValueChange={field.onChange}
                      onBlur={field.onBlur}
                      errorMessage={
                        fieldState.error?.message ||
                        errors?.start?.date?.message ||
                        errors?.start?.time?.message
                      }
                      disabled={cantEdit}
                    />
                  )}
                />
              ) : null}
              <Controller
                name="end"
                control={control}
                render={({ field, fieldState }) => (
                  <DateTimeField
                    label="End Time (Optional)"
                    value={field.value}
                    onValueChange={field.onChange}
                    onBlur={field.onBlur}
                    errorMessage={
                      fieldState.error?.message ||
                      errors?.end?.date?.message ||
                      errors?.end?.time?.message
                    }
                    disabled={cantEdit}
                  />
                )}
              />
              <Row
                justify="space-between"
                sx={{ flexWrap: "wrap" }}
                rowGap={"small"}
              >
                {buttons}
              </Row>
            </Stack>
          </form>
        </FormProvider>
      </Stack>
    </Container>
  );
};
