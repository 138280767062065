import { useCallback } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import { ScheduleItem } from "@ses-mams/api-contract";

import { Button } from "~/components/ui/button";
import { Divider } from "~/components/ui/divider";
import { Stack } from "~/components/ui/stack";
import { useToast } from "~/components/ui/toast";
import { tsr } from "~/utils/client";
import { MyScheduleFormSchema, myScheduleFormSchema } from "./schema";
import { MyScheduleSection } from "./MyScheduleSection";
import { captureException } from "@sentry/react";

type Props = {
  currentSchedule: ScheduleItem[];
};

type StartEndFieldNameArray = (
  | `schedule.${number}.end`
  | `schedule.${number}.start`
)[];

export const MyScheduleForm = ({ currentSchedule }: Props) => {
  const { control, handleSubmit, trigger, getValues } =
    useForm<MyScheduleFormSchema>({
      resolver: zodResolver(myScheduleFormSchema),
      defaultValues: {
        schedule:
          currentSchedule.length > 0 ? currentSchedule : [EMPTY_SCHEDULE_ITEM],
      },
    });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "schedule",
  });

  const { isPending, onSubmit } = useOnSubmitSchedule();

  const handleTriggerTimeValidation = useCallback(
    (index: number) => {
      const impactedFields: StartEndFieldNameArray = [
        `schedule.${index - 1}.end`,
        `schedule.${index}.end`,
        `schedule.${index}.start`,
        `schedule.${index + 1}.start`,
      ];

      const triggerFields = getValues(impactedFields)
        .map((value, index) => {
          if (value) {
            return impactedFields[index];
          }
          return undefined;
        })
        .filter(x => !!x);

      trigger(triggerFields as StartEndFieldNameArray);
    },
    [trigger, getValues]
  );

  const handleDeleteScheduleSection = useCallback(
    (index: number) => {
      remove(index);
      handleTriggerTimeValidation(index - 1);
    },
    [remove, handleTriggerTimeValidation]
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack gap="large">
        {fields.map((field, index) => (
          <MyScheduleSection
            key={field.id}
            index={index}
            control={control}
            // User shouldn't be able to delete the final item
            onDeleteClick={
              fields.length > 1
                ? () => handleDeleteScheduleSection(index)
                : undefined
            }
            triggerTimeValidation={handleTriggerTimeValidation}
          />
        ))}
      </Stack>

      <Stack paddingBottom="large" justify="stretch">
        <Button variant="tertiary" onClick={() => append(EMPTY_SCHEDULE_ITEM)}>
          Add Section
        </Button>
      </Stack>

      <Divider />

      <Stack align="end" paddingTop="large">
        <Button type="submit" busy={isPending}>
          Done
        </Button>
      </Stack>
    </form>
  );
};

const useOnSubmitSchedule = () => {
  const queryClient = tsr.useQueryClient();
  const { addToast } = useToast();

  const { mutateAsync, isPending } = tsr.schedule.set.useMutation();

  const onSubmit = useCallback(async (data: MyScheduleFormSchema) => {
    try {
      const response = await mutateAsync({
        body: data.schedule,
      });

      queryClient.schedule.get.setQueryData(["schedule"], response);
    } catch (error) {
      captureException(error);
      addToast({
        tone: "critical",
        title: "Sorry, something went wrong",
        message: "Please try again",
      });
    }
  }, []);

  return {
    isPending,
    onSubmit,
  };
};

const EMPTY_SCHEDULE_ITEM = { name: "", start: "", end: "" };
