import {
  getNextPageParam as baseGetNextPageParam,
  PaginatedBody,
  PaginatedPage,
  PaginationParams,
} from "@ses-mams/react-utils";

export const DEFAULT_PAGE_SIZE = 20;

export const LARGE_PAGE_SIZE = 100;

export const getNextPageParam = <
  T,
  TBody extends PaginatedBody<T>,
  TPage extends PaginatedPage<TBody>,
  TPageParam extends PaginationParams,
>(
  lastPage: TPage,
  allPages: TPage[],
  lastPageParam: TPageParam
) => baseGetNextPageParam(lastPage, allPages, lastPageParam, DEFAULT_PAGE_SIZE);

export const getNextLargePageParam = <
  T,
  TBody extends PaginatedBody<T>,
  TPage extends PaginatedPage<TBody>,
  TPageParam extends PaginationParams,
>(
  lastPage: TPage,
  allPages: TPage[],
  lastPageParam: TPageParam
) => baseGetNextPageParam(lastPage, allPages, lastPageParam, LARGE_PAGE_SIZE);
