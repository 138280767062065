import { z } from "zod";
import {
  eventDatesFormSchema,
  eventOtherFieldsFormSchema,
  validateDatesSchema,
} from "./event";

const ScheduleDayOfWeek = z.enum([
  "MON",
  "TUE",
  "WED",
  "THU",
  "FRI",
  "SAT",
  "SUN",
]);

type ScheduleReminderTimeUnit = "Minute" | "Hour";
export const SCHEDULE_REMINDER_TIME_UNIT_OPTIONS = ["Minute", "Hour"];

const reminderDurationBeforeStartSchema = z
  .object({
    value: z.number().min(1).nullish(),
    unit: z
      .enum(SCHEDULE_REMINDER_TIME_UNIT_OPTIONS as [ScheduleReminderTimeUnit])
      .nullish(),
  })
  .optional()
  .superRefine((val, ctx) => {
    if (!!val?.unit && !val?.value) {
      ctx.addIssue({
        code: "custom",
        message: "Required",
        path: ["value"],
      });
    }

    // reminder duration should be 23 hours or less to cater for daily recurring activity
    if (val?.unit === "Minute" && val?.value && val?.value > 1380) {
      ctx.addIssue({
        code: "custom",
        message: "Reminder duration must be less than 24 hours",
        path: ["value"],
      });
    }

    if (val?.unit === "Hour" && val?.value && val?.value >= 24) {
      ctx.addIssue({
        code: "custom",
        message: "Reminder duration must be less than 24 hours",
        path: ["value"],
      });
    }
  });

export const activityScheduleForm = z.intersection(
  eventOtherFieldsFormSchema.extend({
    schedule: z
      .object({
        period: z.enum(["Daily", "Weekly", "Monthly"]),
        // Every is actually required, but make it optional so that superRefine can run in order to validate all fields at same time.
        every: z
          .number({ invalid_type_error: "Required" })
          .min(1)
          .max(10000)
          .optional(),
        daysOfWeek: z.array(ScheduleDayOfWeek).optional(),
        dayOfMonth: z
          .number({ invalid_type_error: "Required" })
          .min(1)
          .max(31)
          .optional(),
        nth: z
          .object({
            value: z.number({ invalid_type_error: "Required" }).min(1).max(4),
            dayOfWeek: ScheduleDayOfWeek,
          })
          .optional(),
        monthlyRepeatOnMode: z.enum(["Day of Month", "The..."]).optional(),
        endMode: z.enum(["On Date", "After"]),
        endDate: z.date().optional(),
        endCount: z.number({ invalid_type_error: "Required" }).min(1).nullish(),
        reminderDurationBeforeStart: reminderDurationBeforeStartSchema,
      })
      .superRefine((val, ctx) => {
        if (typeof val.every !== "number") {
          ctx.addIssue({
            code: "custom",
            message: "Required",
            path: ["every"],
          });
        }

        if (val.period === "Monthly") {
          if (!val.monthlyRepeatOnMode) {
            ctx.addIssue({
              code: "custom",
              message: "Required",
              path: ["monthlyRepeatOnMode"],
            });
          }

          if (
            val.monthlyRepeatOnMode === "Day of Month" &&
            typeof val.dayOfMonth !== "number"
          ) {
            ctx.addIssue({
              code: "custom",
              message: "Required",
              path: ["dayOfMonth"],
            });
          }

          if (val.monthlyRepeatOnMode === "The...") {
            if (!val.nth?.value) {
              ctx.addIssue({
                code: "custom",
                message: "Required",
                path: ["nth.value"],
              });
            }

            if (!val.nth?.dayOfWeek) {
              ctx.addIssue({
                code: "custom",
                message: "Required",
                path: ["nth.dayOfWeek"],
              });
            }
          }
        }

        if (val.endMode === "After" && typeof val.endCount !== "number") {
          ctx.addIssue({
            code: "custom",
            message: "Required",
            path: ["endCount"],
          });
        }

        if (val.endMode === "On Date" && !val.endDate) {
          ctx.addIssue({
            code: "custom",
            message: "Required",
            path: ["endDate"],
          });
        }
      }),
  }),
  eventDatesFormSchema.required().superRefine(validateDatesSchema)
);

const editActivityScheduleFormSchema = eventOtherFieldsFormSchema.extend({
  schedule: z
    .object({
      endMode: z.enum(["On Date", "After"]),
      endDate: z.date().optional(),
      endCount: z.number().min(1).nullish(),
      reminderDurationBeforeStart: reminderDurationBeforeStartSchema,
    })
    .superRefine((val, ctx) => {
      if (val.endMode === "After" && typeof val.endCount !== "number") {
        ctx.addIssue({
          code: "custom",
          message: "Required",
          path: ["endCount"],
        });
      }

      if (val.endMode === "On Date" && !val.endDate) {
        ctx.addIssue({
          code: "custom",
          message: "Required",
          path: ["endDate"],
        });
      }
    }),
});

export const getEditActivityScheduleFormSchema = (
  currentEndDate?: Date,
  currentEndCount?: number
) => {
  return editActivityScheduleFormSchema.superRefine(({ schedule }, ctx) => {
    if (
      currentEndDate &&
      schedule.endDate &&
      +schedule.endDate < +currentEndDate
    ) {
      ctx.addIssue({
        code: "custom",
        message: "Unable to shorten the end date",
        path: ["schedule.endDate"],
      });
    }

    if (
      currentEndCount &&
      schedule.endCount &&
      schedule.endCount < currentEndCount
    ) {
      ctx.addIssue({
        code: "custom",
        message: "Unable to reduce the ocurrences",
        path: ["schedule.endCount"],
      });
    }
  });
};

export const convertDurationToMinutes = ({
  value,
  unit,
}: {
  value?: number | null;
  unit?: ScheduleReminderTimeUnit | null;
}) => {
  if (!value || !unit) {
    return undefined;
  }

  return unit === "Hour" ? value * 60 : value;
};

export type ActivityScheduleFormSchema = z.infer<typeof activityScheduleForm>;

export type EditActivityScheduleFormSchema = z.infer<
  typeof editActivityScheduleFormSchema
>;
