import { Container } from "@mui/material";
import { formatDateTime } from "@ses-mams/react-utils";
import { useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BackButton } from "~/components/common/navigation";
import { Box } from "~/components/ui/box";
import { Button } from "~/components/ui/button";
import { Spinner } from "~/components/ui/spinner";
import { Stack } from "~/components/ui/stack";
import { Text } from "~/components/ui/text";
import { useAuth, useHasAnyRole } from "~/context/auth";
import { InitiatorLabelValueDrawer, LabelValue } from "../shared";
import { useGetActivityScheduleQuery } from "../hooks";
import {
  ActivityScheduleMemberList,
  ManageActivityScheduleMembersDrawer,
  RemoveButton,
} from "./components";
import { captureException } from "@sentry/react";
import { useConfirmationDialog } from "~/components/ui/confirmationDialog";
import { TrashOutlineIcon } from "~/components/ui/icon";
import { useToast } from "~/components/ui/toast";
import { tsr } from "~/utils/client";

export const ActivityScheduleDetailsPage = () => {
  const scheduleId = useParams().scheduleId as string;

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const { data: scheduleData, isLoading: isLoadingSchedule } =
    useGetActivityScheduleQuery(scheduleId);

  const { data: isInvitedData, isLoading: isLoadingIsInvited } =
    tsr.activitySchedules.isInvited.useQuery({
      queryKey: ["activity-schedules", "isInvited", scheduleId],
      queryData: {
        params: { activityScheduleId: scheduleId },
      },
    });

  const hasCoordinatorRole = useHasAnyRole(["ActivityCoordinator"]);
  const { member } = useAuth();

  if (
    isLoadingSchedule ||
    isLoadingIsInvited ||
    !scheduleData ||
    !isInvitedData
  ) {
    return (
      <Box display="flex" grow={1} justify="center">
        <Spinner />
      </Box>
    );
  }

  const schedule = scheduleData.body;
  const isClosed = !!schedule.closedAt;
  const canClose = !isClosed && hasCoordinatorRole;
  const canRemove = isInvitedData.body.invited && !isClosed;

  return (
    <>
      <Container maxWidth="md">
        <Stack gap="xlarge">
          <BackButton />

          <Stack gap="xlarge" dividers>
            <Stack direction="row" justify="space-between">
              <Stack>
                <Text size="xlarge" weight="semi">
                  {schedule.title}
                </Text>
                <Text size="xlarge" weight="semi">
                  {schedule.address}
                </Text>
              </Stack>

              {hasCoordinatorRole && (
                <div>
                  <Button href="edit" disabled={isClosed}>
                    Edit
                  </Button>
                </div>
              )}
            </Stack>

            <Stack gap="xlarge">
              <LabelValue label="Description" value={schedule.description} />
              <LabelValue label="Occurs" value={schedule.scheduleSummary} />
              {schedule.scheduleEndDate && (
                <LabelValue
                  label="Ends after"
                  value={formatDateTime(new Date(schedule.scheduleEndDate))}
                />
              )}
              {schedule.scheduleEndCount && (
                <LabelValue
                  label="Ends after"
                  value={`${schedule.scheduleEndCount} occurrences`}
                />
              )}
              {schedule.initiator && (
                <InitiatorLabelValueDrawer initiator={schedule.initiator} />
              )}
            </Stack>
          </Stack>

          <Stack dividers gap="xlarge">
            {/* There's no additional information in the drawer if they don't have permissions, so hide it. */}
            {hasCoordinatorRole && (
              <Button
                variant="tertiary"
                fullWidth
                onClick={() => setIsDrawerOpen(true)}
              >
                Members
              </Button>
            )}
            <Stack
              align={{ xs: "stretch", md: "start" }}
              direction={{ xs: "column", md: "row" }}
              gap="small"
            >
              {canRemove && (
                <RemoveButton
                  scheduleId={scheduleId}
                  memberId={member?.id as string}
                />
              )}
              {canClose && (
                <ArchiveScheduleActivityButton scheduleId={scheduleId} />
              )}
            </Stack>
          </Stack>

          <Stack gap="medium">
            <Text size="xlarge" weight="medium">
              Members
            </Text>
            <ActivityScheduleMemberList scheduleId={scheduleId} />
          </Stack>
        </Stack>
      </Container>

      <ManageActivityScheduleMembersDrawer
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        scheduleId={scheduleId}
        isClosed={isClosed}
        unitId={schedule.unit.id}
      />
    </>
  );
};

const ArchiveScheduleActivityButton = ({
  scheduleId,
}: {
  scheduleId: string;
}) => {
  const navigate = useNavigate();
  const queryClient = tsr.useQueryClient();

  const { mutateAsync, isPending: isClosingSchedule } =
    tsr.activitySchedules.close.useMutation();

  const { showConfirmationDialog } = useConfirmationDialog();
  const { addToast } = useToast();

  const handleDeleteActivity = useCallback(async () => {
    try {
      await mutateAsync({
        params: {
          activityScheduleId: scheduleId,
        },
      });

      await queryClient.invalidateQueries({
        queryKey: ["activity-requests", "list"],
      });
      await queryClient.invalidateQueries({
        queryKey: ["activity-schedules", scheduleId],
      });

      navigate("/requests?tab=activity");
    } catch (error) {
      captureException(error);

      addToast({
        tone: "critical",
        title: "Sorry, something went wrong",
        message: "Please try again",
      });
    }
  }, [scheduleId]);

  return (
    <Box>
      <Button
        variant="destructive"
        busy={isClosingSchedule}
        startIcon={TrashOutlineIcon}
        onClick={() =>
          showConfirmationDialog({
            text: "You will not be able to undo this action.",
            onConfirm: handleDeleteActivity,
          })
        }
      >
        Archive Schedule Activity
      </Button>
    </Box>
  );
};
