import { Spinner } from "~/components/ui/spinner";
import { Autocomplete } from "~/components/ui/autocomplete";
import { Unit } from "@ses-mams/api-contract";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Text } from "~/components/ui/text";
import { Box } from "~/components/ui/box";
import { Stack } from "~/components/ui/stack";

type UnitType = "My Units" | "All Units";

type UnitWithCategory = Unit & { category: UnitType };

type UnitSelectorProps = {
  label?: string;
  units: Unit[];
  selectedUnit?: Unit;
  setSelectedUnit: (value: Unit) => void;
  isLoading?: boolean;
  disabled?: boolean;
  showCategories?: boolean;
  memberUnits?: Unit[];
  errorMessage?: string;
};

export const UnitSelector = ({
  label,
  selectedUnit,
  setSelectedUnit,
  units,
  isLoading,
  disabled,
  memberUnits,
  showCategories,
  errorMessage,
}: UnitSelectorProps) => {
  const [internalSelectedUnit, setInternalSelectedUnit] =
    useState<UnitWithCategory | null>(null);

  const unitsWithCategory: UnitWithCategory[] = useMemo(() => {
    const withCategory: UnitWithCategory[] = units.map(item => {
      if (memberUnits?.some(unit => item.id === unit.id)) {
        return { ...item, category: "My Units" };
      } else {
        return { ...item, category: "All Units" };
      }
    });

    return withCategory;
  }, [memberUnits, units]);

  const handleChange = useCallback(
    (event: React.SyntheticEvent, unit: UnitWithCategory | null) => {
      if (unit) {
        const { category, ...others } = unit;
        setSelectedUnit(others);
        setInternalSelectedUnit(unit);
      }
    },
    []
  );

  useEffect(() => {
    if (!selectedUnit || !unitsWithCategory.length) {
      setInternalSelectedUnit(null);
      return;
    }

    if (!internalSelectedUnit) {
      const newSelectedUnit = selectedUnit?.id
        ? unitsWithCategory.find(u => u.id === selectedUnit?.id)
        : unitsWithCategory[0];

      setInternalSelectedUnit(newSelectedUnit as UnitWithCategory);
    }
  }, [internalSelectedUnit, selectedUnit, unitsWithCategory]);

  if (isLoading || !unitsWithCategory.length) {
    return <Spinner size={24} />;
  }

  return (
    <Autocomplete
      label={label}
      placeholder="Select unit"
      options={unitsWithCategory}
      getOptionLabel={unit =>
        `${unit.name}${unit.code ? ` (${unit.code})` : ""}`
      }
      isOptionEqualToValue={(option, value) => option.id === value?.id}
      value={internalSelectedUnit ?? null}
      onChange={handleChange}
      disabled={disabled}
      sx={{ maxWidth: { xs: 200, sm: "unset" } }}
      groupBy={
        showCategories ? (unit: UnitWithCategory) => unit.category : undefined
      }
      renderGroup={
        showCategories
          ? params => {
              return (
                <Stack key={params.key}>
                  <Box
                    background="surfaceMuted"
                    padding="small"
                    borderBottom="standard"
                    borderBottomWidth="medium"
                    borderTop="standard"
                    borderTopWidth="medium"
                  >
                    <Text weight="semi">{params.group}</Text>
                  </Box>
                  {params.children}
                </Stack>
              );
            }
          : undefined
      }
      errorMessage={errorMessage}
    />
  );
};
