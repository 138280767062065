import { Container } from "@mui/material";
import { MemberBroadcastMessage } from "@ses-mams/api-contract";
import { getTotalCountFromPages } from "@ses-mams/react-utils";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Box } from "~/components/ui/box";
import { Button } from "~/components/ui/button";

import { Heading } from "~/components/ui/heading";
import {
  InfiniteSectionList,
  RenderSectionHeaderParams,
} from "~/components/ui/list";
import { Spinner } from "~/components/ui/spinner";
import { Stack } from "~/components/ui/stack";
import { Text } from "~/components/ui/text";
import { useAuth } from "~/context/auth";
import { tsr } from "~/utils/client";
import { DEFAULT_PAGE_SIZE, getNextPageParam } from "~/utils/pagination";
import { formatDateTime } from "@ses-mams/react-utils";
import { EmptyState } from "~/components/common/emptyState";
import emptyListImageUrl from "~/assets/emptyList.png";
import { RequestCard } from "../requests/shared";
import { Dialog, DialogContent, DialogHeader } from "~/components/ui/dialog";
import { captureException } from "@sentry/react";
import { useToast } from "~/components/ui/toast";
import { AlertCircleFilledIcon } from "~/components/ui/icon";

type BroadcastItemSectionLabel = "Unacknowledged" | "Previous";

export const MyBroadcastMessagesPage = () => {
  const [selectedUnacknowledgedMessageId, setSelectedUnacknowledgedMessageId] =
    useState<string | undefined>();

  const [selectedBroadcastMessage, setSelectedBroadcastMessage] = useState<
    MemberBroadcastMessage | undefined
  >();

  const { isLoading, sections, hasNextPage, fetchNextPage } =
    useBroadcastMessages();

  const unacknowledgedItems = useMemo(() => {
    const section =
      sections.find(s => s.title === "Unacknowledged")?.data || [];

    let firstItem = null;
    const restItems = section.filter((broadcast: MemberBroadcastMessage) => {
      if (broadcast.id === selectedUnacknowledgedMessageId) {
        firstItem = broadcast;
        return false;
      }
      return true;
    });

    return [...(firstItem ? [firstItem] : []), ...restItems];
  }, [sections, selectedUnacknowledgedMessageId]);

  const renderSectionHeader = useCallback(
    ({ title }: RenderSectionHeaderParams<MemberBroadcastMessage>) => {
      return (
        <Box
          background="surface"
          position="sticky"
          paddingY="small"
          sx={{ top: 0, zIndex: 10 }}
        >
          <Text weight="medium" size="large">
            {title}
          </Text>
        </Box>
      );
    },
    []
  );

  const renderItem = useCallback(
    (broadcastMessage: MemberBroadcastMessage) => {
      return (
        <Box
          display="flex"
          paddingBottom="medium"
          sx={{ width: "100%", maxWidth: "100%" }}
        >
          <RequestCard
            to=""
            onClick={() => {
              if (broadcastMessage.acknowledged) {
                setSelectedBroadcastMessage(broadcastMessage);
                return;
              }
              setSelectedUnacknowledgedMessageId(broadcastMessage.id);
            }}
          >
            <Stack
              direction="row"
              justify="space-between"
              align="center"
              sx={{ width: "100%" }}
            >
              <Stack gap="xsmall">
                <Heading level="5">{broadcastMessage.title}</Heading>

                <Text
                  noWrap
                  sx={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxWidth: 200,
                  }}
                >
                  {broadcastMessage.description}
                </Text>

                <Text size="standard" tone="secondary">
                  {formatDateTime(new Date(broadcastMessage.createdAt))}
                </Text>
              </Stack>

              {!broadcastMessage?.acknowledged && (
                <AlertCircleFilledIcon tone="critical" />
              )}
            </Stack>
          </RequestCard>
        </Box>
      );
    },
    [unacknowledgedItems]
  );

  return (
    <Container maxWidth="sm">
      <Stack gap="medium" dividers>
        <Heading level="1">Broadcast Messages</Heading>
        {isLoading ? (
          <Box display="flex" justify="center" sx={{ height: "100%" }}>
            <Spinner />
          </Box>
        ) : (
          <>
            <InfiniteSectionList
              sections={sections}
              keyExtractor={item => item.id}
              hasNextPage={hasNextPage}
              fetchNextPage={fetchNextPage}
              renderSectionHeader={renderSectionHeader}
              renderItem={renderItem}
              listItemDivider={false}
              listEmptyElement={
                isLoading ? null : (
                  <Box display="flex" justify="center" sx={{ height: "100%" }}>
                    <EmptyState
                      image={<img src={emptyListImageUrl} />}
                      title="No broadcasts messages"
                      description="There are no broadcast messages found."
                    />
                  </Box>
                )
              }
            />
            <UnacknowledgedBroadcastMessageDialog
              showModal={!!selectedUnacknowledgedMessageId}
              onClose={() => {
                setSelectedUnacknowledgedMessageId(undefined);
              }}
              broadcasts={unacknowledgedItems}
            />
            <PreviousBroadcastMessageDialog
              showModal={!!selectedBroadcastMessage}
              onClose={() => {
                setSelectedBroadcastMessage(undefined);
              }}
              broadcast={selectedBroadcastMessage}
            />
          </>
        )}
      </Stack>
    </Container>
  );
};

const PreviousBroadcastMessageDialog = ({
  showModal,
  onClose,
  broadcast,
}: {
  showModal: boolean;
  onClose: () => void;
  broadcast?: MemberBroadcastMessage;
}) => {
  return (
    <Dialog open={showModal} onClose={onClose} fullWidth maxWidth="md">
      {broadcast?.title && (
        <DialogHeader onClose={onClose}>{broadcast.title}</DialogHeader>
      )}
      <DialogContent>
        <Box sx={{ minHeight: 200 }}>
          <Text size="large">{broadcast?.description}</Text>
        </Box>
      </DialogContent>
      <Stack gap="small" paddingX="large" paddingY="small">
        <Button variant="secondary" onClick={onClose}>
          Back to list
        </Button>
      </Stack>
    </Dialog>
  );
};
const UnacknowledgedBroadcastMessageDialog = ({
  showModal,
  onClose,
  broadcasts,
}: {
  showModal: boolean;
  onClose: () => void;
  broadcasts?: MemberBroadcastMessage[];
}) => {
  const queryClient = tsr.useQueryClient();
  const { addToast } = useToast();
  const { mutateAsync, isPending } =
    tsr.broadcastAcknowledgements.acknowledge.useMutation();
  const { member } = useAuth();

  const currentBroadcast = useMemo(() => {
    if (broadcasts) {
      return broadcasts[0];
    }
    return;
  }, [broadcasts]);

  useEffect(() => {
    if (!currentBroadcast) {
      onClose();
    }
  }, [currentBroadcast]);

  const handleAcknowledge = useCallback(async () => {
    try {
      await mutateAsync({
        params: {
          broadcastId: currentBroadcast?.id || "",
        },
      });

      queryClient.invalidateQueries({
        queryKey: ["member-broadcasts", { memberId: member?.id }],
      });
      queryClient.invalidateQueries({
        queryKey: [
          "unacknowledged-member-broadcasts",
          { memberId: member?.id },
        ],
      });
    } catch (error) {
      captureException(error);
      addToast({
        tone: "critical",
        title: "Sorry, something went wrong",
        message: "Please try again",
      });
    }
  }, [currentBroadcast?.id, member?.id]);

  return (
    <Dialog open={showModal} onClose={onClose} fullWidth maxWidth="md">
      {currentBroadcast?.title && (
        <DialogHeader onClose={onClose}>{currentBroadcast.title}</DialogHeader>
      )}
      <DialogContent>
        <Box sx={{ minHeight: 200 }}>
          <Text size="large">{currentBroadcast?.description}</Text>
        </Box>
      </DialogContent>
      <Stack gap="small" paddingX="large" paddingY="small">
        <Button variant="secondary" onClick={onClose}>
          Back to list
        </Button>
        <Button variant="primary" onClick={handleAcknowledge} busy={isPending}>
          Acknowledge
        </Button>
      </Stack>
    </Dialog>
  );
};

const useBroadcastMessages = () => {
  const { member } = useAuth();

  const { data, isLoading, isFetchingNextPage, hasNextPage, fetchNextPage } =
    tsr.members.getBroadcastMessages.useInfiniteQuery({
      queryKey: ["member-broadcasts", { memberId: member?.id }],
      queryData: ({ pageParam }) => ({
        params: {
          memberId: member?.id || "",
        },
        query: {
          skip: pageParam?.skip ?? 0,
          take: pageParam?.take ?? DEFAULT_PAGE_SIZE,
          platform: "web",
        },
      }),
      initialPageParam: { skip: 0, take: DEFAULT_PAGE_SIZE },
      getNextPageParam,
    });

  const sections = useMemo(() => {
    const sectionMap: Record<
      BroadcastItemSectionLabel,
      MemberBroadcastMessage[]
    > = {
      Unacknowledged: [],
      Previous: [],
    };

    (data?.pages || []).flatMap(page => {
      if (page.status !== 200) {
        return;
      }

      page.body.items.forEach(item => {
        if (item.acknowledged === true) {
          sectionMap["Previous"].push(item);
        } else {
          sectionMap["Unacknowledged"].push(item);
        }
      });
    });

    return Object.keys(sectionMap).flatMap(key => {
      const typedKey = key as keyof typeof sectionMap;
      const keyedData = sectionMap[typedKey];

      if (!keyedData.length) {
        return [];
      }

      return [
        {
          title: typedKey,
          data: keyedData,
        },
      ];
    });
  }, [data?.pages]);

  const totalCount = getTotalCountFromPages(data?.pages);

  return {
    isLoading,
    isFetchingNextPage,
    hasNextPage,
    sections,
    totalCount,
    fetchNextPage,
  };
};
