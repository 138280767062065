import { useMemo } from "react";

export type PaginatedBody<T> = {
  totalCount?: number;
  items: T[];
};

export type PaginatedPage<TBody> = { status: number; body: TBody };

export type PaginationParams = {
  skip: number;
  take: number;
};

export const getNextPageParam = <
  T,
  TBody extends PaginatedBody<T>,
  TPage extends PaginatedPage<TBody>,
  TPageParam extends PaginationParams,
>(
  lastPage: TPage,
  allPages: TPage[],
  lastPageParam: TPageParam,
  pageSize: number
) => {
  const lastPageTakeParam = lastPageParam?.take ?? 0;
  const lastPageItemsLength = lastPage.body?.items?.length ?? 0;

  if (
    lastPage.status === 200 &&
    // Fetch more data if the last page has more items than the page size setting or if the last page param is less than the page size setting.
    // This should remove any edges cases of under fetching data.
    (lastPageItemsLength >= pageSize || lastPageTakeParam < pageSize)
  ) {
    let skip = 0;

    allPages.forEach(page => {
      skip += page.body?.items?.length ?? 0;
    });

    return { take: pageSize, skip };
  }

  // Returning undefined signals that there is no more data to fetch.
  return undefined;
};

export const getTotalCountFromPages = <
  T,
  TBody extends PaginatedBody<T>,
  TPage extends PaginatedPage<TBody>,
>(
  pages: TPage[] = []
) => pages[(pages?.length ?? 0) - 1]?.body?.totalCount ?? 0;

// This comes from @tanstack/query-core/src/types.ts. Just didn't want to install a new dependency only for this type.
type InfiniteData<T> = {
  pages: Array<T>;
  pageParams: Array<unknown>;
};

type TPage<T> = {
  status: number;
  body: {
    items: Array<T>;
  };
};

export const useTransformPagedDataToItems = <T>(
  data?: InfiniteData<TPage<T>>
) =>
  useMemo(
    () =>
      (data?.pages || []).flatMap(page =>
        page.status === 200 ? page.body.items : []
      ),
    [data?.pages]
  );

export const getUpdatedPagedData = <T>(newItems: T[]) => {
  const totalCount = newItems.length;

  const page = {
    status: 200,
    body: {
      totalCount,
      items: newItems,
    },
  };

  return {
    pages: [page],
    pageParams: [{ skip: 0, take: totalCount }],
  };
};
