import { useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";

import { ActivationRequestMember } from "@ses-mams/api-contract";
import { getUpdatedPagedData } from "../utils";

export const useUpdateActivationMembersQueryCache = (activationId: string) => {
  const queryClient = useQueryClient();

  return useCallback(
    (items: Array<ActivationRequestMember>) => {
      return Promise.all([
        queryClient.setQueryData(
          ["activations", activationId, "members", "", []],
          getUpdatedPagedData(items)
        ),
        queryClient.invalidateQueries({
          queryKey: ["activations", activationId],
          exact: true,
        }),
        queryClient.invalidateQueries({ queryKey: ["activation-requests"] }),
      ]);
    },
    [activationId]
  );
};
