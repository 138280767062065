import { ButtonBase } from "@mui/material";
import { captureException } from "@sentry/react";
import { OutOfAreaActivationRequestPreApprovedMember } from "@ses-mams/api-contract";
import {
  formatDateTime,
  formatMemberFullName,
  getUpdatedPagedData,
  useArrayState,
} from "@ses-mams/react-utils";
import { useCallback, useMemo, useState } from "react";
import { EmptyState } from "~/components/common/emptyState";
import { SelectAllMembersToggle } from "~/components/common/selectMembers";
import { Button } from "~/components/ui/button";
import { Checkbox } from "~/components/ui/checkbox";
import { useConfirmationDialog } from "~/components/ui/confirmationDialog";
import {
  Drawer,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerProps,
} from "~/components/ui/drawer";
import { Spinner } from "~/components/ui/spinner";
import { Stack } from "~/components/ui/stack";
import { Text } from "~/components/ui/text";
import { useToast } from "~/components/ui/toast";
import { tsr } from "~/utils/client";

type PreApprovedOOAAMembersDrawerProps = Omit<DrawerProps, "children"> & {
  activationId: string;
};

export const PreApprovedOOAAMembersDrawer = ({
  activationId,
  ...props
}: PreApprovedOOAAMembersDrawerProps) => {
  const [selectedMemberIds, { append, remove, setValue, clear }] =
    useArrayState<string>([]);
  const [isSelectAllChecked, setIsSelectAllChecked] = useState(false);

  const { data, isLoading: isLoadingMembers } =
    tsr.outOfAreaActivations.getPreApprovedMembers.useQuery({
      queryKey: [
        "out-of-area-activation",
        { activationId },
        "pre-approved-members",
      ],
      queryData: {
        params: {
          activationId,
        },
      },
      enabled: props.open,
    });

  const items = data?.body.items || [];

  const handleSelectAllPress = useCallback(
    (checked: boolean) => {
      setValue(checked ? items.map(item => item.id) : []);
      setIsSelectAllChecked(checked);
    },
    [isSelectAllChecked, items]
  );

  const onSuccess = useCallback(() => {
    clear();
    props.onClose();
  }, [clear, props.onClose]);

  const { activate, isPending: isActivating } =
    useActivateOutOfAreaPreApprovedMembers({
      activationId,
      requests: useMemo(
        () =>
          selectedMemberIds.flatMap(id => {
            const selectedItem = items.find(
              item => item.id === id
            ) as OutOfAreaActivationRequestPreApprovedMember;

            if (!selectedItem) {
              // protect against undefined error
              return [];
            }

            return [
              {
                memberId: selectedItem.id,
                deploymentIds: selectedItem.deployments.map(d => d.id),
              },
            ];
          }),
        [items, selectedMemberIds]
      ),
      onSuccess,
    });

  const { showConfirmationDialog } = useConfirmationDialog();

  const handleActivateClick = useCallback(() => {
    showConfirmationDialog({
      title: "Activate members",
      text: "Are you sure you want to activate these members? This will override their current status and any existing commitments.",
      onConfirm: activate,
    });
  }, [activate]);

  return (
    <Drawer {...props}>
      <DrawerHeader {...props}>Pre-approved members</DrawerHeader>

      <DrawerContent>
        {isLoadingMembers ? (
          <Stack align="center">
            <Spinner />
          </Stack>
        ) : items.length ? (
          <Stack gap="medium">
            <SelectAllMembersToggle
              totalCount={items.length}
              selected={isSelectAllChecked}
              onClick={handleSelectAllPress}
            />

            <Stack>
              {items.map(item => {
                const isChecked = selectedMemberIds.some(
                  memberId => memberId === item.id
                );

                return (
                  <ButtonBase
                    role="checkbox"
                    aria-checked={isChecked}
                    onClick={() =>
                      isChecked ? remove(item.id) : append(item.id)
                    }
                  >
                    <Stack
                      flex={1}
                      direction="row"
                      gap="medium"
                      align="start"
                      paddingY="small"
                    >
                      <Checkbox checked={isChecked} />

                      <Stack gap="medium" align="start">
                        <Text tone="info" weight="semi">
                          {formatMemberFullName(item)}
                        </Text>
                        {item.deployments.map(d => {
                          return (
                            <Stack key={d.id} gap="xsmall" align="start">
                              <Text weight="semi">Availabilities:</Text>
                              <Text tone="secondary">
                                {`${formatDateTime(
                                  new Date(d.start),
                                  "long"
                                )} - ${formatDateTime(
                                  new Date(d.end),
                                  "long"
                                )}`}
                              </Text>
                              <Text weight="semi">Roles:</Text>
                              <Text tone="secondary">
                                {d.roles.map(r => r.name).join(", ")}
                              </Text>
                            </Stack>
                          );
                        })}
                      </Stack>
                    </Stack>
                  </ButtonBase>
                );
              })}
            </Stack>
          </Stack>
        ) : (
          <Stack grow={1} justify="center">
            <EmptyState
              title="No members found."
              description="No pre-approved members for this request."
            />
          </Stack>
        )}
      </DrawerContent>

      <DrawerFooter>
        <Button
          busy={isActivating}
          disabled={!selectedMemberIds.length}
          onClick={handleActivateClick}
        >{`Activate${
          selectedMemberIds.length ? ` (${selectedMemberIds.length})` : ""
        }`}</Button>
      </DrawerFooter>
    </Drawer>
  );
};

type Params = {
  activationId: string;
  requests: Array<{
    memberId: string;
    deploymentIds: Array<string>;
  }>;
  onSuccess: () => void;
};

const useActivateOutOfAreaPreApprovedMembers = ({
  activationId,
  requests,
  onSuccess,
}: Params) => {
  const queryClient = tsr.useQueryClient();

  const { mutateAsync, isPending } =
    tsr.outOfAreaActivations.activatePreApprovedMembers.useMutation();

  const { addToast } = useToast();

  const activate = useCallback(async () => {
    try {
      const result = await mutateAsync({
        params: { activationId },
        body: {
          requests,
        },
      });

      queryClient.setQueriesData(
        {
          queryKey: ["out-of-area-activation", { activationId }, "members-v2"],
        },
        getUpdatedPagedData(result.body)
      );

      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: [
            "out-of-area-activation-requests",
            "details",
            { activationId },
          ],
          exact: true,
        }),
        queryClient.invalidateQueries({
          queryKey: [
            "out-of-area-activation",
            { activationId },
            "pre-approved-members",
          ],
        }),
        queryClient.invalidateQueries({
          queryKey: ["out-of-area-activation-requests", "list"],
        }),
      ]);

      onSuccess();
    } catch (error) {
      captureException(error);

      addToast({
        tone: "critical",
        title: "Sorry, something went wrong",
        message: "Please try again",
      });
    }
  }, [activationId, requests, onSuccess, mutateAsync, queryClient, addToast]);

  return {
    activate,
    isPending,
  };
};
