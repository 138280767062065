import { useActivationRequestsData } from "./hooks";
import { OperationalListItem } from "./OperationalListItem";
import { RequestList } from "../../../shared";
import { useCallback } from "react";
import { tsr } from "~/utils/client";
import { useToast } from "~/components/ui/toast";
import { captureException } from "@sentry/react";
import { useBulkSelectingState } from "@ses-mams/react-utils";

type OperationalListProps = {
  unitId?: string;
};

export const OperationalList = ({ unitId }: OperationalListProps) => {
  const queryClient = tsr.useQueryClient();

  const { mutateAsync, isPending: isClosing } =
    tsr.activations.closeMany.useMutation();

  const { addToast } = useToast();

  const { isBulkSelecting, setIsBulkSelecting, onSelect, selectedIds } =
    useBulkSelectingState();

  const handleCloseActivations = useCallback(async () => {
    try {
      await mutateAsync({
        body: {
          activationIds: [...selectedIds],
        },
      });

      await queryClient.invalidateQueries({
        queryKey: ["activation-requests", "list"],
      });

      setIsBulkSelecting(false);
    } catch (error) {
      captureException(error);

      addToast({
        tone: "critical",
        title: "Sorry, something went wrong",
        message: "Please try again",
      });
    }
  }, [selectedIds]);

  return (
    <RequestList
      unitId={unitId}
      dataHook={useActivationRequestsData}
      ListItemComponent={OperationalListItem}
      onConfirm={handleCloseActivations}
      isClosing={isClosing}
      isBulkSelecting={isBulkSelecting}
      setIsBulkSelecting={setIsBulkSelecting}
      selectedIds={selectedIds}
      onBulkSelect={onSelect}
      bulkCloseRoles={["OperationalCoordinator"]}
    />
  );
};
