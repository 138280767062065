import { captureException } from "@sentry/react";
import { addMilliseconds, format } from "date-fns";
import { useCallback, useState } from "react";

import { Box } from "~/components/ui/box";
import { Button } from "~/components/ui/button";
import { Dialog, DialogContent } from "~/components/ui/dialog";
import { Stack } from "~/components/ui/stack";
import { Spinner } from "~/components/ui/spinner";
import { useToast } from "~/components/ui/toast";
import { Text } from "~/components/ui/text";
import { tsr } from "~/utils/client";

type ChannelMuteNotificationsProps = {
  channelId: string;
};

export const ChannelMuteNotifications = ({
  channelId,
}: ChannelMuteNotificationsProps) => {
  const [isDialogVisible, setIsDialogVisible] = useState(false);

  const { isPending: isUpdatingChannelSettings, mutateChannelSettings } =
    useMutateChannelSettings();

  const { data: channelSettingsData, isLoading: isLoadingChannelSettings } =
    tsr.channels.getChannelSettings.useQuery({
      queryKey: ["channel-settings", channelId],
      queryData: {
        params: {
          channelId,
        },
      },
    });

  const handleCloseMuteSheet = useCallback(() => {
    setIsDialogVisible(false);
  }, []);

  const handleUpdateMuteDuration = useCallback(
    async (muteDurationInMilliSeconds: number) => {
      const isAlwaysMuted = muteDurationInMilliSeconds === 0;

      await mutateChannelSettings({
        channelId,
        muteChannel: true,
        muteChannelExpiry: isAlwaysMuted
          ? null
          : addMilliseconds(
              new Date(),
              muteDurationInMilliSeconds
            ).toISOString(),
      });

      handleCloseMuteSheet();
    },
    [channelId]
  );

  const handleUnmute = useCallback(async () => {
    await mutateChannelSettings({
      channelId,
      muteChannel: false,
      muteChannelExpiry: null,
    });

    handleCloseMuteSheet();
  }, [channelId]);

  const channelSettings = channelSettingsData?.body;
  const expiry = channelSettings?.muteChannelExpiry;
  const isMuteChannelExpired = expiry && new Date() > new Date(expiry);
  const isMuted =
    Boolean(channelSettings?.muteChannel) && !isMuteChannelExpired;

  if (isLoadingChannelSettings || isUpdatingChannelSettings) {
    return (
      <Box display="flex" justify="center" align="center">
        <Spinner size={24} />
      </Box>
    );
  }

  return (
    <>
      <Button variant="link" onClick={() => setIsDialogVisible(true)}>
        <Stack>
          {isMuted ? "Muted" : "Mute Channel"}
          {!isMuteChannelExpired && expiry ? (
            <Text tone="secondary">{`Until ${format(
              new Date(expiry),
              "dd/MMM/YYY, h:mma"
            )}`}</Text>
          ) : null}
        </Stack>
      </Button>

      <Dialog open={isDialogVisible} onClose={handleCloseMuteSheet}>
        <DialogContent>
          {isMuted ? (
            <Button variant="secondary" onClick={handleUnmute}>
              Unmute
            </Button>
          ) : (
            <Stack gap="medium">
              {MUTE_DURATION_OPTIONS.map(({ label, value }) => {
                return (
                  <Button
                    key={`${label}-option`}
                    variant="secondary"
                    onClick={() => handleUpdateMuteDuration(value)}
                  >
                    {label}
                  </Button>
                );
              })}
            </Stack>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

type MutateParams = {
  channelId: string;
  muteChannel: boolean;
  muteChannelExpiry?: string | null;
};

const useMutateChannelSettings = () => {
  const queryClient = tsr.useQueryClient();
  const { addToast } = useToast();

  const { isPending, mutateAsync } =
    tsr.channels.updateChannelSettings.useMutation();

  const mutateChannelSettings = useCallback(
    async ({ channelId, muteChannel, muteChannelExpiry }: MutateParams) => {
      try {
        const updatedChannelSettings = await mutateAsync({
          params: {
            channelId,
          },
          body: {
            muteChannel,
            muteChannelExpiry,
          },
        });

        queryClient.channels.getChannelSettings.setQueryData(
          ["channel-settings", channelId],
          updatedChannelSettings
        );
      } catch (error) {
        captureException(error);

        addToast({
          tone: "critical",
          title: "Sorry, something went wrong",
          message: "Please try again",
        });
      }
    },
    []
  );

  return { isPending, mutateChannelSettings };
};

const MUTE_DURATION_OPTIONS = [
  {
    label: "30 minutes",
    value: 1800000,
  },
  {
    label: "2 hours",
    value: 7200000,
  },
  {
    label: "Always",
    value: 0,
  },
];
