import { initContract } from "@ts-rest/core";
import { z } from "zod";
import {
  ApiErrorSchema,
  CapabilitySchema,
  MemberSummarySchema,
  PaginationQueryParamsSchema,
  SearchMembersFilterSchema,
  ServerDateSchema,
  UnitSummarySchema,
  getPaginationResponseSchema,
} from "./common";
import { OutOfAreaActivationRoleSchema } from "./outOfAreaActivationRole";
import { ClusterSchema } from "./clusters";
import { ZoneSchema } from "./zones";

const c = initContract();

export const OutOfAreaActivationLevelSchema = z.enum([
  "State",
  "InterZone",
  "IntraZone",
  "InterCluster",
  "IntraCluster",
  "InterUnit",
]);

export const OutOfAreaEscalationTimeUnitSchema = z.enum([
  "Minute",
  "Hour",
  "Day",
  "Week",
]);

export const OutOfAreaTypeSchema = z.enum(["Urgent", "NotUrgent"]);

export const OutOfAreaActivationRequestStatusSchema = z.enum([
  "AvailabilityRequested",
  "UnitApprovalPending",
  "UnitApprovalDeclined",
  "ClusterApprovalPending",
  "ClusterApprovalDeclined",
  "ZoneApprovalPending",
  "ZoneApprovalDeclined",
  "ActivationPending",
  "Activated",
  "Deactivated",
  "Withdrawn",
]);

export const OutOfAreaActivationAvailabilityStatusSchema = z.enum([
  "Available",
  "Conditional",
  "Unavailable",
]);

export const OutOfAreaActivationRequestAvailabilityBlockSchema = z.object({
  id: z.string(),
  start: ServerDateSchema,
  end: ServerDateSchema,
  availabilityStatus: OutOfAreaActivationAvailabilityStatusSchema.nullish(),
  conditionalReason: z.string().nullish(),
});

export const OutOfAreaActivationRequestSchema = z.object({
  id: z.string(),
  member: MemberSummarySchema.extend({
    capabilities: z.array(CapabilitySchema),
  }),
  roles: z.array(OutOfAreaActivationRoleSchema),
  availabilityBlocks: z.array(
    OutOfAreaActivationRequestAvailabilityBlockSchema
  ),
  status: OutOfAreaActivationRequestStatusSchema,
  blocked: z.boolean().optional(),
  unsuccessful: z.boolean(),
});

export const DeploymentInputSchema = z.object({
  location: z.string().optional(),
  address: z.string().optional(),
  latitude: z.number().optional(),
  longitude: z.number().optional(),
  description: z.string().optional(),
  start: ServerDateSchema,
  end: ServerDateSchema,
  roleIds: z.array(z.string()).optional(),
});

const EditOutOfAreaInputSchema = z.object({
  type: OutOfAreaTypeSchema,
  title: z.string(),
  description: z.string(),
  responseDate: ServerDateSchema,
  deployments: z
    .array(DeploymentInputSchema)
    .min(1, "At least one deployment is required."),
  /**
   * @deprecated use addMembers instead
   */
  recipientZoneIds: z.array(z.string()).optional(),
  /**
   * @deprecated use addMembers instead
   */
  recipientClusterIds: z.array(z.string()).optional(),
  /**
   * @deprecated use addMembers instead
   */
  recipientGroupIds: z.array(z.string()).optional(),
  /**
   * @deprecated use addMembers instead
   */
  recipientUnitIds: z.array(z.string()).optional(),
  /**
   * @deprecated use addMembers instead
   */
  recipientMemberIds: z.array(z.string()).optional(),
  /**
   * @deprecated use addMembers instead
   */
  recipientCapabilityIds: z.array(z.string()).optional(),
  /**
   * @deprecated use addMembers instead
   */
  selectAllMembersQuery: SearchMembersFilterSchema.optional(),
});

const CreateOutOfAreaInputSchema = EditOutOfAreaInputSchema.extend({
  level: OutOfAreaActivationLevelSchema,
  unitId: z.string().optional(),
  clusterId: z.string().optional(),
  zoneId: z.string().optional(),
  unitEscalationThreshold: z.number().min(30).optional(),
  clusterEscalationThreshold: z.number().min(30).optional(),
});

export const OutOfAreaActivationSummarySchema = z.object({
  id: z.string(),
  title: z.string(),
  description: z.string(),
  type: OutOfAreaTypeSchema,
  replyByDate: ServerDateSchema,
  closeReason: z.string().optional(),
  closedAt: z.string().datetime().optional(),
  level: OutOfAreaActivationLevelSchema,
  unitEscalationThreshold: z.number().optional(),
  clusterEscalationThreshold: z.number().optional(),
  zoneEscalationThreshold: z.number().optional(),
});

const OutOfAreaActivationDeploymentSchema = z.object({
  id: z.string(),
  location: z.string().optional(),
  address: z.string().optional(),
  latitude: z.number().optional(),
  longitude: z.number().optional(),
  description: z.string().optional(),
  start: ServerDateSchema,
  end: ServerDateSchema,
  roles: z.array(OutOfAreaActivationRoleSchema),
});

export const OutOfAreaActivationSchema =
  OutOfAreaActivationSummarySchema.extend({
    deployments: z.array(OutOfAreaActivationDeploymentSchema),
    unit: UnitSummarySchema.optional(),
    cluster: ClusterSchema.optional(),
    zone: ZoneSchema.optional(),
    initiator: MemberSummarySchema,
  });

const OutOfAreaActivationRequestMemberSchema = z.object({
  request: OutOfAreaActivationRequestSchema.extend({
    member: MemberSummarySchema.extend({
      capabilities: z.array(CapabilitySchema),
      units: z.array(UnitSummarySchema),
    }),
  }),
  statusUpdatedAt: z.string().datetime(),
});

const OutOfAreaActivationRequestMemberSummarySchema = z.object({
  request: z.object({
    id: z.string(),
    member: MemberSummarySchema,
    status: OutOfAreaActivationRequestStatusSchema,
    blocked: z.boolean().optional(),
    unsuccessful: z.boolean(),
  }),
});

const OutOfAreaActivationRequestPreApprovedMemberSchema =
  MemberSummarySchema.extend({
    deployments: z.array(OutOfAreaActivationDeploymentSchema),
  });

export const outOfAreaActivationContract = c.router({
  create: {
    method: "POST",
    path: "/out-of-area-activations",
    body: CreateOutOfAreaInputSchema,
    responses: {
      200: OutOfAreaActivationSchema,
      403: ApiErrorSchema,
    },
    summary: "Create an out of area activation",
  },
  getMembers: {
    method: "GET",
    path: "/out-of-area-activations/:activationId/members",
    query: PaginationQueryParamsSchema.extend({
      roles: z.array(z.string()).optional(),
      activatedStatus: z.boolean().optional(),
      approvedStatus: z.boolean().optional(),
      rejectedStatus: z.boolean().optional(),
      zonePendingStatus: z.boolean().optional(),
      clusterPendingStatus: z.boolean().optional(),
      unitPendingStatus: z.boolean().optional(),
      awaitingStatus: z.boolean().optional(),
      unitId: z.coerce.string().optional(),
      clusterId: z.coerce.string().optional(),
      zoneId: z.coerce.string().optional(),
      name: z.coerce.string().optional(),
      statusUpdatedAt: ServerDateSchema.optional(),
    }),
    responses: {
      200: getPaginationResponseSchema(OutOfAreaActivationRequestMemberSchema),
    },
    summary: "Get the members of an out of area activation",
  },
  getMemberSummaries: {
    method: "GET",
    path: "/out-of-area-activations/:activationId/member-summaries",
    query: PaginationQueryParamsSchema,
    responses: {
      200: getPaginationResponseSchema(
        OutOfAreaActivationRequestMemberSummarySchema
      ),
    },
    summary: "Get the member summaries of an out of area activation",
  },
  getPreApprovedMembers: {
    method: "GET",
    path: "/out-of-area-activations/:activationId/pre-approved-members",
    responses: {
      200: z.object({
        items: z.array(OutOfAreaActivationRequestPreApprovedMemberSchema),
      }),
    },
    summary: "Get the pre-approved members of an out of area activation",
  },
  removeMembers: {
    method: "DELETE",
    path: "/out-of-area-activations/:activationId/members",
    body: z.object({
      memberIds: z.array(z.string()),
    }),
    responses: {
      200: z.object({
        items: z.array(OutOfAreaActivationRequestMemberSchema),
      }),
    },
    summary: "Remove members from an out of area activation",
  },
  addMembers: {
    method: "POST",
    path: "/out-of-area-activations/:activationId/members",
    pathParams: z.object({
      activationId: z.string(),
    }),
    body: z.object({
      memberIds: z.array(z.string()),
      zoneIds: z.array(z.string()).optional(),
      clusterIds: z.array(z.string()).optional(),
      groupIds: z.array(z.string()).optional(),
      unitIds: z.array(z.string()).optional(),
      capabilityIds: z.array(z.string()).optional(),
      selectAllMembersQuery: SearchMembersFilterSchema.optional(),
    }),
    responses: {
      200: z.object({
        items: z.array(OutOfAreaActivationRequestMemberSchema),
      }),
      403: ApiErrorSchema,
    },
    summary: "Add members to an out of area activation",
  },
  get: {
    method: "GET",
    path: "/out-of-area-activations/:id",
    pathParams: z.object({ id: z.string() }),
    responses: {
      200: OutOfAreaActivationSchema,
      404: ApiErrorSchema,
    },
  },
  update: {
    method: "PATCH",
    path: "/out-of-area-activations/:id",
    pathParams: z.object({ id: z.string() }),
    body: EditOutOfAreaInputSchema,
    responses: {
      200: OutOfAreaActivationSchema,
      403: ApiErrorSchema,
      404: ApiErrorSchema,
      400: ApiErrorSchema,
    },
  },
  activate: {
    method: "POST",
    path: "/out-of-area-activations/:activationId/activate",
    responses: {
      200: z.array(OutOfAreaActivationRequestMemberSchema),
      403: ApiErrorSchema,
      422: ApiErrorSchema,
    },
    body: z.object({
      requestIds: z.array(z.string()),
      force: z.boolean().optional(),
    }),
    summary: "Bulk action to activate out of area activation requests",
  },
  deactivate: {
    method: "POST",
    path: "/out-of-area-activations/:activationId/deactivate",
    responses: {
      200: z.array(OutOfAreaActivationRequestMemberSchema),
      403: ApiErrorSchema,
    },
    body: z.object({
      requestIds: z.array(z.string()),
    }),
    summary: "Bulk action to deactivate out of area activation requests",
  },
  markUnsuccessful: {
    method: "POST",
    path: "/out-of-area-activations/:activationId/unsuccessful",
    responses: {
      200: z.object({
        items: z.array(OutOfAreaActivationRequestMemberSchema),
      }),
    },
    body: z.object({
      memberIds: z.array(z.string()),
    }),
    summary:
      "Bulk action to mark members as unsuccessful for out of area activation requests",
  },
  listCloseReasons: {
    method: "GET",
    path: "/out-of-area-activations/close-reasons",
    responses: {
      200: z.array(z.string()),
    },
    summary: "List close reasons",
  },
  close: {
    method: "DELETE",
    path: "/out-of-area-activations/:id",
    pathParams: z.object({
      id: z.string(),
    }),
    body: z.object({
      reason: z.string(),
    }),
    responses: {
      200: OutOfAreaActivationSchema,
      403: ApiErrorSchema,
      404: ApiErrorSchema,
    },
    summary: "Close an out of area activation",
  },
  closeMany: {
    method: "DELETE",
    path: "/out-of-area-activations",
    body: z.object({
      activationIds: z.array(z.string()),
      reason: z.string(),
    }),
    responses: {
      200: null,
      403: ApiErrorSchema,
    },
    summary: "Close multiple out of area activations",
  },
  activatePreApprovedMembers: {
    method: "POST",
    path: "/out-of-area-activations/:activationId/pre-approved-members/activate",
    responses: {
      200: z.array(OutOfAreaActivationRequestMemberSchema),
      403: ApiErrorSchema,
      422: ApiErrorSchema,
    },
    body: z.object({
      requests: z.array(
        z.object({
          memberId: z.string(),
          deploymentIds: z.array(z.string()),
        })
      ),
    }),
    summary: "Bulk action to activate pre-approved members",
  },
});

export type OutOfAreaActivationRequestAvailabilityBlock = z.infer<
  typeof OutOfAreaActivationRequestAvailabilityBlockSchema
>;

export type OutOfAreaActivationRequestStatus = z.infer<
  typeof OutOfAreaActivationRequestStatusSchema
>;

export type OutOfAreaActivationLevel = z.infer<
  typeof OutOfAreaActivationLevelSchema
>;
export type OutOfAreaType = z.infer<typeof OutOfAreaTypeSchema>;

export type OutOfAreaEscalationTimeUnit = z.infer<
  typeof OutOfAreaEscalationTimeUnitSchema
>;

export type OutOfAreaActivationDeployment = z.infer<
  typeof OutOfAreaActivationDeploymentSchema
>;

export type OutOfAreaActivationSummary = z.infer<
  typeof OutOfAreaActivationSummarySchema
>;

export type OutOfAreaActivation = z.infer<typeof OutOfAreaActivationSchema>;

export type OutOfAreaActivationRequestMember = z.infer<
  typeof OutOfAreaActivationRequestMemberSchema
>;

export type OutOfAreaActivationRequestMemberSummary = z.infer<
  typeof OutOfAreaActivationRequestMemberSummarySchema
>;

export type OutOfAreaActivationRequestPreApprovedMember = z.infer<
  typeof OutOfAreaActivationRequestPreApprovedMemberSchema
>;
