import { TextField } from "~/components/ui/textField";
import {
  OC_RESTRICTED_OUT_OF_AREA_ACTIVATION_LEVEL_OPTIONS,
  OUT_OF_AREA_ACTIVATION_LEVEL_OPTIONS,
  OUT_OF_AREA_TYPE_OPTIONS,
  OutOfAreaActivationFormSchema,
} from "@ses-mams/validation";
import { Controller, useFormContext } from "react-hook-form";
import { Button } from "~/components/ui/button";
import { Divider } from "~/components/ui/divider";
import { Heading } from "~/components/ui/heading";
import { Stack } from "~/components/ui/stack";
import { SelectField, SelectItem } from "~/components/ui/selectField";
import {
  SelectAllMembersQuery,
  formatActivationType,
  formatTwentyFourHourTime,
} from "@ses-mams/react-utils";
import { DateTimeField } from "~/components/ui/dateTimeField";
import { OutOfAreaActivationLevelForm } from "~/components/common/outOfAreaActivationLevel";
import { addDays, addHours } from "date-fns";
import { EscalationTimesForm } from "./EscalationTimesForm";
import { DeploymentForm } from "./DeploymentForm";
import { useHasAnyRole } from "~/context/auth";
import { useCallback, useMemo, useState } from "react";
import {
  Capability,
  Cluster,
  Group,
  Member,
  OutOfAreaActivation,
  Unit,
  Zone,
} from "@ses-mams/api-contract";
import { EditZonesList } from "~/components/common/editZonesList";
import { EditClustersList } from "~/components/common/editClustersList";
import { EditGroupsList } from "~/components/common/editGroupsList";
import { EditUnitsList } from "~/components/common/editUnitsList";
import { EditCapabilitiesList } from "~/components/common/editCapabilitiesList";
import { EditMemberList } from "~/components/common/editMemberList";
import { useOutOfAreaActivationMutations } from "./useOutOfAreaActivationMutations";
import { useNavigate } from "react-router-dom";

type Props = {
  activation?: OutOfAreaActivation;
};

export const OutOfAreaActivationForm = ({ activation }: Props) => {
  const isEditing = !!activation;
  const isOoaaCoordinator = useHasAnyRole(["OOAACoordinator"]);
  const navigate = useNavigate();

  const [zones, setZones] = useState<Array<Zone>>([]);
  const [clusters, setClusters] = useState<Array<Cluster>>([]);
  const [groups, setGroups] = useState<Array<Group>>([]);
  const [units, setUnits] = useState<Array<Unit>>([]);
  const [capabilities, setCapabilities] = useState<Array<Capability>>([]);
  const [members, setMembers] = useState<Array<Member>>([]);
  const [selectAllMembersQuery, setSelectAllMembersQuery] =
    useState<SelectAllMembersQuery>();

  const { createOoaaActivation, updateOoaaActivation } =
    useOutOfAreaActivationMutations();

  const onSubmit = useCallback(
    async (formData: OutOfAreaActivationFormSchema) => {
      if (activation) {
        await updateOoaaActivation(activation.id, {
          formData,
          zones,
          clusters,
          groups,
          units,
          capabilities,
          members,
          selectAllMembersQuery,
        });
        navigate(`/requests/out-of-area-activations/${activation.id}`);
      } else {
        await createOoaaActivation({
          formData,
          zones,
          clusters,
          groups,
          units,
          capabilities,
          members,
          selectAllMembersQuery,
        });
        navigate("/requests?tab=out-of-area");
      }
    },
    [
      activation,
      zones,
      clusters,
      groups,
      units,
      capabilities,
      members,
      selectAllMembersQuery,
    ]
  );

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, errors },
    setValue,
    register,
    watch,
  } = useFormContext<OutOfAreaActivationFormSchema>();

  const unit = watch("unit");
  const cluster = watch("cluster");
  const zone = watch("zone");

  const activationLevel = watch("level");

  const showZoneSection = useMemo(() => {
    return ["State", "InterZone"].includes(activationLevel ?? "");
  }, [activationLevel]);

  const showClusterSection = useMemo(() => {
    return !["IntraCluster", "InterUnit"].includes(activationLevel ?? "");
  }, [activationLevel]);

  const activatedValue = useMemo(() => {
    return unit?.name ?? cluster?.name ?? zone?.name ?? undefined;
  }, [unit, cluster, zone]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack gap="xlarge" paddingBottom="xxlarge">
        <Stack direction="row" justify="space-between" paddingBottom="xlarge">
          <Heading level="1">
            {isEditing ? "Edit" : "New"} Out of Area Request
          </Heading>
          <Button type="submit" busy={isSubmitting}>
            Done
          </Button>
        </Stack>
        <Divider />

        <OutOfAreaActivationLevelForm
          control={control}
          setValue={setValue}
          disabled={isEditing}
          levelOptions={
            isOoaaCoordinator
              ? OUT_OF_AREA_ACTIVATION_LEVEL_OPTIONS
              : OC_RESTRICTED_OUT_OF_AREA_ACTIVATION_LEVEL_OPTIONS
          }
        />

        <Controller
          name="type"
          control={control}
          render={({ field: { value, onChange }, fieldState }) => (
            <SelectField
              label="Type"
              placeholder="Type"
              value={value}
              errorMessage={fieldState.error?.message}
            >
              {OUT_OF_AREA_TYPE_OPTIONS.map(type => (
                <SelectItem
                  key={type}
                  value={type}
                  onClick={() => onChange(type)}
                >
                  {formatActivationType(type as "Urgent" | "NotUrgent")}
                </SelectItem>
              ))}
            </SelectField>
          )}
        />

        <TextField
          {...register("title")}
          label="Title"
          placeholder="Enter title"
          errorMessage={errors.title?.message}
        />
        <TextField
          {...register("description")}
          label="Description"
          placeholder="Enter description"
          errorMessage={errors.description?.message}
          multiline
          rows={4}
        />

        <Divider />

        <Controller
          name="response"
          control={control}
          render={({ field, fieldState }) => (
            <DateTimeField
              label="Member responses with approvals to be received by"
              value={field.value}
              onValueChange={field.onChange}
              onBlur={field.onBlur}
              errorMessage={
                fieldState.error?.message ||
                errors?.response?.date?.message ||
                errors?.response?.time?.message
              }
            />
          )}
        />

        <Stack direction="row" gap="medium">
          <Button
            variant="tertiary"
            onClick={() => {
              const nextHour = addHours(new Date(), 1);
              setValue("response", {
                date: nextHour,
                time: formatTwentyFourHourTime(nextHour),
              });
            }}
          >
            1 hour
          </Button>
          <Button
            variant="tertiary"
            onClick={() => {
              const tomorrow = addDays(new Date(), 1);
              setValue("response", {
                date: tomorrow,
                time: formatTwentyFourHourTime(tomorrow),
              });
            }}
          >
            1 day
          </Button>
          <Button
            variant="tertiary"
            onClick={() => {
              const nextWeek = addDays(new Date(), 7);
              setValue("response", {
                date: nextWeek,
                time: formatTwentyFourHourTime(nextWeek),
              });
            }}
          >
            1 week
          </Button>
        </Stack>

        <Divider />

        <EscalationTimesForm isDisabled={isEditing} />

        <DeploymentForm />

        {!isEditing && (
          <Stack gap="xlarge">
            <Stack gap="medium">
              <Heading level="3">Add Members</Heading>
              <Divider />
            </Stack>

            <EditMemberList
              showAvailabilityStatus
              selectedMembers={members}
              onChangeSelectedMembers={setMembers}
              selectAllMembersQuery={selectAllMembersQuery}
              setSelectAllMembersQuery={setSelectAllMembersQuery}
            />

            <Stack gap="medium">
              <Heading level="3">Add Collections</Heading>
              <Divider />
            </Stack>

            {showZoneSection && (
              <EditZonesList
                selectedZones={zones}
                onChangeSelectedZones={setZones}
              />
            )}

            {showClusterSection && (
              <EditClustersList
                selectedClusters={clusters}
                onChangeSelectedClusters={setClusters}
              />
            )}

            <EditGroupsList
              selectedGroups={groups}
              onChangeSelectedGroups={setGroups}
            />

            <EditUnitsList
              selectedUnits={units}
              onChangeSelectedUnits={setUnits}
            />

            <EditCapabilitiesList
              selectedCapabilities={capabilities}
              onChangeSelectedCapabilities={setCapabilities}
              unitId={unit?.id ?? undefined}
              clusterId={cluster?.id ?? undefined}
              zoneId={zone?.id ?? undefined}
              disclaimer={
                activatedValue
                  ? `Showing capabilities for ${activatedValue}`
                  : undefined
              }
            />
          </Stack>
        )}
      </Stack>
    </form>
  );
};
