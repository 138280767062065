import { useCallback } from "react";

import { Role } from "@ses-mams/api-contract";

import { tsr } from "~/utils/client";

type UseMemberPermissionsListDataParams = {
  searchValue: string;
  skip: number;
  take: number;
};

export function useMemberPermissionsListData({
  searchValue,
  skip,
  take,
}: UseMemberPermissionsListDataParams) {
  const { data, isLoading, isRefetching, refetch } =
    tsr.admin.listMembers.useQuery({
      queryKey: ["members", searchValue, skip, take],
      queryData: {
        query: {
          skip,
          take,
          query: searchValue,
        },
      },
    });

  return {
    isLoading,
    isRefetching,
    data: data?.body.items ?? [],
    totalCount: data?.body.totalCount ?? 0,
    refetch,
  };
}

export function useOverrideRolesOnMember() {
  const { mutateAsync, isPending } = tsr.admin.overrideRoles.useMutation();
  const queryClient = tsr.useQueryClient();

  const overrideRoles = useCallback(
    async (memberId: string, roles: Role[] | null) => {
      await mutateAsync({
        params: { memberId },
        body: { roles },
      });
      await queryClient.invalidateQueries({
        queryKey: ["members"],
      });
    },
    []
  );

  return { overrideRoles, isPending };
}
