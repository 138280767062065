import { MemberStatus } from "@ses-mams/api-contract";
import { useCallback, useState } from "react";

import { useToast } from "~/components/ui/toast";
import { useShowConflictConfirmationDialog } from "~/hooks/useShowConflictConfirmationDialog";
import { tsr } from "~/utils/client";

export const useMutateActivationStatus = () => {
  const queryClient = tsr.useQueryClient();
  const { addToast } = useToast();

  // Hijack control of loading state to avoid flashes of stale state inbetween request finishing and cache updating
  const [isLoading, setIsLoading] = useState(false);

  const { mutateAsync, variables } =
    tsr.activationRequests.updateActivationStatus.useMutation();

  const { showConflictConfirmationDialog } = useShowConflictConfirmationDialog({
    type: "activation",
  });

  const mutateActivationStatus = useCallback(
    async ({
      requestId,
      status,
      force = false,
    }: {
      requestId: string;
      status: MemberStatus | "Unavailable";
      force?: boolean;
    }) => {
      setIsLoading(true);

      try {
        const updatedActivationRequest = await mutateAsync({
          params: {
            activationRequestId: requestId,
          },
          body: {
            status: status === "Unavailable" ? null : status,
            force,
          },
        });

        await Promise.all([
          queryClient.activationRequests.get.setQueryData(
            ["activation-requests", "details", requestId],
            updatedActivationRequest
          ),

          queryClient.invalidateQueries({
            queryKey: ["activation-requests", "list"],
          }),
          queryClient.invalidateQueries({
            queryKey: ["activation-requests", "details"],
          }),

          queryClient.invalidateQueries({
            queryKey: [
              "activations",
              updatedActivationRequest.body.activation.id,
              "members",
              "",
              [],
            ],
          }),
        ]);
      } catch (error) {
        const apiError = error as any;
        if (apiError?.status === 422) {
          showConflictConfirmationDialog({
            onConfirm: () =>
              mutateActivationStatus({
                requestId,
                status,
                force: true,
              }),
          });
          return;
        }

        if (apiError?.status === 400) {
          addToast({
            tone: "critical",
            title: "Sorry, something went wrong",
            message: apiError?.body?.message,
          });

          queryClient.invalidateQueries({
            queryKey: ["activation-requests", "list"],
          });

          return;
        }

        addToast({
          tone: "critical",
          title: "Sorry, something went wrong",
          message: "Please try again",
        });
      } finally {
        setIsLoading(false);
      }
    },
    []
  );

  return {
    mutateActivationStatus,
    isLoading,
    variables,
  };
};
