import { captureException } from "@sentry/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { differenceInMinutes } from "date-fns";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { ActivationRequest } from "@ses-mams/api-contract";
import {
  formatEta,
  formatPreActivatedEtaInMinutes,
  getPreActivatedEtaInMinutes,
  isActivatedToRequest,
} from "@ses-mams/react-utils";
import { EtaFormSchema, etaFormSchema } from "@ses-mams/validation";
import { Button } from "~/components/ui/button";
import { Stack } from "~/components/ui/stack";
import {
  Drawer,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
} from "~/components/ui/drawer";
import { useToast } from "~/components/ui/toast";
import { Heading } from "~/components/ui/heading";
import { Text } from "~/components/ui/text";
import { TextField } from "~/components/ui/textField";
import { tsr } from "~/utils/client";

type SetRequestEtaDrawerButtonProps = {
  activationRequest: ActivationRequest;
};

export const SetRequestEtaDrawerButton = ({
  activationRequest,
}: SetRequestEtaDrawerButtonProps) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const onClose = useCallback(() => setIsDrawerOpen(false), []);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<EtaFormSchema>({
    resolver: zodResolver(etaFormSchema),
    mode: "onBlur",
  });

  const queryClient = tsr.useQueryClient();

  const { mutateAsync, isPending: isMutatingEta } =
    tsr.activationRequests.updateEta.useMutation();

  const { addToast } = useToast();

  const activationRequestId = activationRequest.id;
  const activation = activationRequest.activation;
  const eta = activationRequest.eta;
  const memberStatus = activationRequest.memberStatus;
  const activatedToRequest = isActivatedToRequest(activationRequest);

  const formattedEta = useMemo(() => {
    if (activatedToRequest) {
      if (activationRequest?.eta) {
        return formatEta(new Date(activationRequest.eta));
      }

      return null;
    }

    const preActivatedEtaInMinutes =
      getPreActivatedEtaInMinutes(activationRequest);

    return preActivatedEtaInMinutes
      ? formatPreActivatedEtaInMinutes(preActivatedEtaInMinutes)
      : null;
  }, [activatedToRequest, activationRequest?.eta]);

  useEffect(() => {
    if (!eta) {
      return;
    }

    const preActivatedEtaInMinutes =
      getPreActivatedEtaInMinutes(activationRequest);
    if (preActivatedEtaInMinutes) {
      setValue("minutes", preActivatedEtaInMinutes.toString(), {
        shouldValidate: false,
      });
      return;
    }

    setValue(
      "minutes",
      differenceInMinutes(new Date(eta), new Date(), {
        roundingMethod: "ceil",
      }).toString(),
      { shouldValidate: false }
    );
  }, [eta, activationRequest]);

  const onSubmit = useCallback(
    async (formData: EtaFormSchema) => {
      try {
        if (!activationRequestId) {
          return;
        }

        await mutateAsync({
          params: {
            activationRequestId,
          },
          body: {
            minutes: formData.minutes ? Number(formData.minutes) : null,
          },
        });

        await Promise.all([
          queryClient.invalidateQueries({
            queryKey: ["activation-requests", "details", activation.id],
          }),
          queryClient.invalidateQueries({
            queryKey: ["activation-requests", "list"],
          }),
        ]);

        onClose();
      } catch (error) {
        captureException(error);

        addToast({
          tone: "critical",
          title: "Sorry, something went wrong",
          message: "Please try again",
        });
      }
    },
    [activationRequestId, activation.id]
  );

  return (
    <>
      <Button
        variant="tertiary"
        fullWidth
        onClick={() => setIsDrawerOpen(true)}
      >
        {`ETA${formattedEta ? ` - ${formattedEta}` : ""}`}
      </Button>

      <Drawer open={isDrawerOpen} onClose={onClose}>
        <DrawerHeader onClose={onClose}>Set ETA</DrawerHeader>
        <form
          onSubmit={handleSubmit(onSubmit)}
          style={{ display: "flex", flexDirection: "column", height: "100%" }}
        >
          <DrawerContent>
            <Stack gap="large">
              <Heading level="4">{`How long is it going to take you to get to ${
                memberStatus === "GoingToJob" ? "the location" : "HQ"
              }?`}</Heading>
              <Stack
                padding="medium"
                gap="xxsmall"
                background="criticalMuted"
                borderRadius="large"
              >
                <Text size="large" weight="semi" tone="critical">
                  Location
                </Text>
                <Text size="large" weight="medium">
                  {memberStatus === "GoingToJob" ? activation?.location : "HQ"}
                </Text>
              </Stack>

              <Stack gap="small">
                <TextField
                  {...register("minutes")}
                  label={`ETA to ${
                    memberStatus === "GoingToJob" ? "location" : "HQ"
                  }`}
                  placeholder="Minutes away"
                  errorMessage={errors.minutes?.message}
                />
                <Text tone="secondary">
                  Enter a value if you would like to manually set your ETA.
                </Text>
              </Stack>
            </Stack>
          </DrawerContent>
          <DrawerFooter>
            <Button type="submit" busy={isMutatingEta}>
              Done
            </Button>
          </DrawerFooter>
        </form>
      </Drawer>
    </>
  );
};
