import { tsr } from "~/utils/client";
import { useCallback } from "react";
import { captureException } from "@sentry/react";
import { useToast } from "~/components/ui/toast";
import { useConfirmationDialog } from "~/components/ui/confirmationDialog";
import { getUpdatedPagedData } from "@ses-mams/react-utils";

type UseRemoveMembersParams = {
  scheduleId: string;
  confirmationText: string;
  onSuccess: () => void;
};

export const useRemoveActivityScheduleMembers = ({
  scheduleId,
  confirmationText,
  onSuccess,
}: UseRemoveMembersParams) => {
  const queryClient = tsr.useQueryClient();

  const { addToast } = useToast();

  const { showConfirmationDialog } = useConfirmationDialog();

  const { mutateAsync, isPending } =
    tsr.activitySchedules.removeMembers.useMutation();

  const removeMembers = useCallback(
    async (memberIds: Array<string>) => {
      try {
        const result = await mutateAsync({
          params: {
            activityScheduleId: scheduleId,
          },
          body: {
            memberIds,
          },
        });

        await Promise.all([
          queryClient.setQueryData(
            ["activity-schedules", scheduleId, "members"],
            getUpdatedPagedData(result.body.items)
          ),

          queryClient.invalidateQueries({
            queryKey: ["activity-schedules", scheduleId],
            exact: true,
          }),

          queryClient.invalidateQueries({
            queryKey: ["activity-schedules", "isInvited", scheduleId],
            exact: true,
          }),
        ]);

        onSuccess();
      } catch (error) {
        captureException(error);

        addToast({
          tone: "critical",
          title: "Sorry, something went wrong",
          message: "Please try again",
        });
      }
    },
    [scheduleId, onSuccess]
  );

  const handleConfirmRemoveMembers = useCallback(
    (memberIds: Array<string>) => {
      showConfirmationDialog({
        text: confirmationText,
        onConfirm: () => removeMembers(memberIds),
      });
    },
    [confirmationText]
  );

  return {
    handleConfirmRemoveMembers,
    isPending,
  };
};
